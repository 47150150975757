import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { RadioField } from '../../../../core/models/form-fields';
import { BaseFieldComponent } from '../base-field/base-field.component';

@Component({
  selector: 'app-radio-field',
  templateUrl: './radio-field.component.html',
  styleUrls: ['./radio-field.component.scss']
})
export class RadioFieldComponent extends BaseFieldComponent  implements OnInit {
  @Input() field: RadioField;

  get label() {
    return (this.field.label !== undefined) ? `${this.field.label}` : '';
  }

  get asterisk() {
    let asterisk = '';
    if (this.field.validation) {
      asterisk = Object.keys(this.field.validation).includes('required') ? this.field.validation.required === true ? '*' : '' : '';
    }

    return asterisk;
  }

  get options() {
    return this.field.options;
  }
  get placement() {
    return this.field.placement || 'horizontal';
  }
  get direction() {
    return this.placement === 'horizontal' ?
      'row' : 'column';
  }

  constructor() {
    super();
    this.control = new FormControl();
  }

  ngOnInit(): void {
    this.setValidations();
    this.form.addControl(this.field.name, this.control);
  }

}
