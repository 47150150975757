export type FormField = TextField | EmailField | RadioField | RadioGroupField | CheckboxField | ButtonField | FreeTextField | PaymentMethodField | TextareaField | GooglePlacesField;
export type ValidationTypes = {
  email?: boolean;
  required?: boolean;
  regex?: string;
  maxLength?: number;
  minLength?: number;
  phone?: boolean;
  postcode?: boolean;
};

export enum PaymentMethods {
  PAYPAL = 'paypal',
  SEPA_DIRECT_DEBIT = 'ddsepa',
  CREDIT_CARD = 'creditcard',
  IDEAL = 'ideal'
}

export enum BankProviders {
  abnamro = 'ABN AMRO',
  asnbank = 'ASN Bank',
  ing = 'ING',
  rabobank = 'Rabobank',
  snsbank = 'SNS Bank',
  snsregiobank = 'RegioBank',
  triodosbank = 'Triodos Bank',
  vanlanschot = 'Van Lanschot',
  knab = 'Knab bank',
  bunq = 'bunq',
  moneyou = 'Moneyou',
  handelsbanken = 'Handelsbanken',
  revolut = 'Revolut'
}

export enum FormFieldType  {
  FREE_TEXT = 'freeText',
  TEXT = 'text',
  EMAIL = 'email',
  TEL = 'tel',
  RADIO = 'radio',
  RADIO_GROUP = 'radio-group',
  CHECKBOX = 'checkbox',
  BUTTON = 'button',
  PAYMENT = 'payment',
  GOOGLE_PLACES = 'google-places',
  TEXTAREA = 'textarea'
}

export interface BaseField {
  type: Extract<FormFieldType, string>;
  name: string;
  className?: string;
  group?: string;
  label?: string;
  transformer?: string;
  value?: string | number;
  width?: number;
  suffix?: string;
  validation?: ValidationTypes;
  excludeFromForm: boolean;
  hideOnPrefill: boolean;
}

export interface TextField extends BaseField {
  type: FormFieldType.TEXT;
  mask?: string;
}

export interface EmailField extends BaseField {
  type: FormFieldType.EMAIL;
  mask?: string;
}

export interface GooglePlacesField extends BaseField {
  type: FormFieldType.TEXT;
  mask?: string;
  countryRestriction?: string;
  placeholder?: string;

  // Child Field Properties.
  zipcodeLabel?: string;
  zipcodeRegex?: string;
  zipcodeValue?: string;
  zipCodeTransformer?: string;
  houseNumberLabel?: string;
  houseNumberRegex?: string;
  houseNumberValue?: string;
  streetLabel?: string;
  streetRegex?: string;
  streetValue?: string;
  cityLabel?: string;
  cityRegex?: string;
  cityValue?: string;
  countryLabel?: string;
  countryRegex?: string;
  countryValue?: string;
}

export interface RadioField extends BaseField {
  type: FormFieldType.RADIO;
  placement: 'horizonal' | 'vertical';
  options: Option[];
}

export interface RadioGroupField extends BaseField {
  type: FormFieldType.RADIO_GROUP;
  placement: 'horizonal' | 'vertical';
  values: RadioOption[];
}

export interface PaymentMethodField extends BaseField {
  type: FormFieldType.PAYMENT;
  methods: PaymentMethodOption[];
  subText?: string;
  ibanLabel?: string;
  ibanSuffix?: string;
  bicLabel?: string;
  bicRegex?: string;
  bicSuffix?: string;
  donationAmountRegex?: string;
}

export interface CheckboxField extends BaseField {
  type: FormFieldType.CHECKBOX;
  options: Option[];
  checked?: boolean;
}

export interface PaymentMethodOption {
  label: string;
  value: string;
  frequencies: string[];
}

export interface Option {
  label: string;
  value: string;
}

export interface RadioOption extends Option {
  selected: boolean;
}

export interface ButtonField {
  type: FormFieldType.BUTTON;
  label: string;
  action: string;
  group?: string;
  className?: string;
  alignment?: 'left' | 'center' | 'right';
  fullWidth?: boolean;
  excludeFromForm: boolean;
}

export interface FreeTextField {
  type: FormFieldType.FREE_TEXT;
  className?: string;
  group?: string;
  value: string;
  excludeFromForm: boolean;
}

export interface TextareaField extends BaseField {
  type: FormFieldType.TEXTAREA;
  mask?: string;
}
