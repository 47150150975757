import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { clientId } from '../../../../environments/client';
import { Dictionary } from '../../models/global';
import { catchError, map } from 'rxjs/operators';

export interface MarketingCloudResponse {
  status: string,
  data: Dictionary<string | number>;
}

@Injectable({
  providedIn: 'root'
})
export class PrefillService {

  /**
   * @constant {string} GATEWAY_MC_PREFILL_PATH
   */
  readonly GATEWAY_MC_PREFILL_PATH = 'mc-prefill';

  /**
   * @param {HttpClient} http
   */
  constructor(private http: HttpClient) { }

  /**
   *
   * @param {string} slug
   * @param {any} queryParams
   *
   * @returns
   */
  getMarketingCloudData(slug: string, queryParams: Dictionary<string>): Observable<Dictionary<any>> {

    const url = `${environment.apis.gatewayEndpoint}/${this.GATEWAY_MC_PREFILL_PATH}/${clientId}/${slug}/${queryParams.id}/${queryParams.hash}`;
    return this.http.get<MarketingCloudResponse>(url, {
      headers: {},
    }).pipe(
      map(response => response.data),
      catchError((error: HttpErrorResponse) => {
        return of({
          error: true,
          message: error.message
        });
      }),
    );

  }
}
