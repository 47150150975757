import { Injectable } from '@angular/core';
import { getCurrencySymbol, formatCurrency } from '@angular/common';
import { TemplateService } from '../services/template/template.service';

@Injectable({
  providedIn: 'root'
})
export class CurrencyUtil {
  constructor(protected templateService: TemplateService)  {}

  /**
   * Formats a number as currency using locale rules.
   * 
   * @uses getCurrencySymbol to retrieve the currency symbol of the given currency code.
   *
   * @param value The given value to format
   * @param code The currency code
   * @param format The format, `wide` or `narrow`
   */
  format(value: number, code: string, format: 'wide' | 'narrow' = 'narrow'): string {
    try {
      // If no decimals are found, remove the cents.
      const decimalFormat = (value % 1 == 0) ? '1.0-0' : '1.2-2';

      return formatCurrency(
        value, 
        this.templateService.language, 
        this.getCurrencySymbol(code, format), 
        null,
        decimalFormat
      );
    } catch (e) {
      // Handle expected errors... or throw them
      throw e;
    }
  }

  /**
   * @inheritdoc
   */
  getCurrencySymbol(code: string, format: 'wide' | 'narrow' = 'narrow', locale?: string): string {
    return getCurrencySymbol(code, format, locale);
  }
}
