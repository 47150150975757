import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatRadioChange } from '@angular/material/radio';
import { RadioGroupField } from '../../../../core/models/form-fields';
import { BaseFieldComponent } from '../base-field/base-field.component';

@Component({
  selector: 'app-radio-group-field',
  templateUrl: './radio-group-field.component.html',
  styleUrls: ['./radio-group-field.component.scss']
})
export class RadioGroupFieldComponent extends BaseFieldComponent  implements OnInit {
  @Input() field: RadioGroupField;

  get label() {
    return (this.field.label !== undefined) ? `${this.field.label}` : '';
  }

  get asterisk() {
    let asterisk = '';
    if (this.field.validation) {
      asterisk = Object.keys(this.field.validation).includes('required') ? this.field.validation.required === true ? '*' : '' : '';
    }

    return asterisk;
  }

  get values() {
    return this.field.values;
  }

  get placement() {
    return this.field.placement || 'horizontal';
  }

  get direction() {
    return this.placement === 'horizontal' ?
      'row' : 'column';
  }

  constructor() {
    super();
    this.control = new FormControl();
  }

  ngOnInit(): void {
    this.setValidations();
    this.form.addControl(this.field.name, this.control);
    if (this.field.value) {
      this.control.setValue(this.field.value);
    }

    // Set the initial value of the FormControl if radioField.selected is defined.
    this.values.forEach(radioField => {
      if(radioField.selected) {
        this.control.setValue(radioField.value);
      }
    });
  }

  onChange(event: MatRadioChange): void {
    this.control.setValue(event.value);
  }
}
