import { Component, HostBinding, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-notice',
  templateUrl: './notice.component.html',
  styleUrls: ['./notice.component.scss']
})
export class NoticeComponent {

  @Input() message = '';
  @Input() @HostBinding('class.visible') visible = false;

  constructor() { }

  hide() {
    this.visible = false;
  }

}
