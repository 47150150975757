import { Component, Input, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Article } from 'src/app/core/models/template';

@Component({
  selector: 'app-content-article',
  templateUrl: './content-article.component.html',
  styleUrls: ['./content-article.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ContentArticleComponent {

  @Input() article: Article;
  get content() {
    return this.sanitizer.bypassSecurityTrustHtml(this.article.text);
  }

  constructor(
    private sanitizer: DomSanitizer,
  ) { }


}
