import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { FreeTextField } from 'src/app/core/models/form-fields';

@Component({
  selector: 'app-free-text-field',
  templateUrl: './free-text-field.component.html',
  styleUrls: ['./free-text-field.component.scss']
})
export class FreeTextFieldComponent implements OnInit {
  @Input() field: FreeTextField;

  get value() {
    return this.sanitizer.bypassSecurityTrustHtml(this.field.value);
  }

  constructor(
    private sanitizer: DomSanitizer,
  ) {}

  ngOnInit(): void {}
}
