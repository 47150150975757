
<div
  class="form-engine"
  fxLayout="column"
  fxLayout.gt-xs="row"
  fxLayoutGap="8px"
  *ngFor="let columns of rows$ | async; let i = index">
  <div
    *ngFor="let column of columns"
    class="form-column"
    [fxFlex.gt-xs]="column.width || 100"
    flex="100"
    [ngSwitch]="column.type">

    <app-text-field
      *ngSwitchCase="switchTextFields(column.type)"
      [form]="form"
      [class]="column.className"
      [field]="column">
    </app-text-field>

    <app-free-text-field
      *ngSwitchCase="formFieldTypes.FREE_TEXT"
      [class]="column.className"
      [field]="column">
    </app-free-text-field>

    <app-textarea-field
      *ngSwitchCase="formFieldTypes.TEXTAREA"
      [form]="form"
      [class]="column.className"
      [field]="column">
    </app-textarea-field>

    <app-radio-field
      *ngSwitchCase="formFieldTypes.RADIO"
      [form]="form"
      [class]="column.className"
      [field]="column">
    </app-radio-field>

    <app-radio-group-field
      *ngSwitchCase="formFieldTypes.RADIO_GROUP"
      [form]="form"
      [class]="column.className"
      [field]="column">
    </app-radio-group-field>

    <app-checkbox-field
      *ngSwitchCase="formFieldTypes.CHECKBOX"
      [form]="form"
      [class]="column.className"
      [field]="column">
    </app-checkbox-field>

    <app-payment-method-field
      *ngSwitchCase="formFieldTypes.PAYMENT"
      [form]="form"
      [class]="column.className"
      [field]="column">
    </app-payment-method-field>

    <app-google-places-field
    *ngSwitchCase="formFieldTypes.GOOGLE_PLACES"
    [form]="form"
    [field]="column">
  </app-google-places-field>

    <app-button-field
      *ngSwitchCase="formFieldTypes.BUTTON"
      #submitButton
      [class]="column.className"
      [state]="buttonState"
      [form]="form"
      (submit)="doSubmit($event)"
      [field]="column">
    </app-button-field>
  </div>
</div>
