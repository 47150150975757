import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dynamic'
})
export class DynamicPipe implements PipeTransform {
  /**
   * This function for using strip all the spaces and capitalize the entire string
   * @param str
   */
  stripSpacesAndUpperCaseConverter(str: string): string {
    return str.split(' ').join('').toUpperCase();
  }
  /**
   * This function for using the zipcode convertor
   * @param str
   */
  zipCodeConverter(str: string): string {
    const tmp = str.split(' ').join('');
    const regex = /^[1-9][0-9]{3}[a-z]{2}$/i;
    if (regex.test(tmp)) {
      return tmp.substring(0, 4) + ' ' + tmp.substring(4).toUpperCase();
    }
    return str;
  }
  /**
   * This function is for converting the phone number
   * @param str
   */
  phoneConverter(str: string): string {
    let converted = str;
    if (converted.includes('+31')) {
      converted = converted.replace('+31', '0');
    }
    else if (converted.startsWith('0+')) {
      converted = converted.replace('0', '');
    }
    else {
      const numbers = converted.split(' ');

      if (numbers[1].startsWith('0')) {
        numbers[1] = numbers[1].replace('0', '');
      }
      converted = numbers.join('');
    }

    return converted
      .replace(/ *\([^)]*\) */g, '').split('-').join('')
      .split(' ').join('');
  }
  /**
   * This function for using the initial convertor
   * @param word
   */
  initialConverter(word: string): string {
    const wordRemovedPoints = word.split('.').join('');
    const result = wordRemovedPoints.match(/.{1,1}/g).map(n => n[0].toUpperCase()).join('.');
    return result + '.';
  }
  /**
   * Now we could use which transformer we have to put in the dynamic pipe
   * @param word
   * @param Transformer
   */
  transform(word: string, Transformer: string): string {
    switch (Transformer) {
      case 'initialConverter': {
        return this.initialConverter(word);
      }
      case 'zipCodeConverter': {
        return this.zipCodeConverter(word);
      }
      case 'stripSpacesAndUpperCaseConverter': {
        return this.stripSpacesAndUpperCaseConverter(word);
      }
    }
  }
}
