<div>
  <div *ngIf="label" class="payment-labels">
    <h3>{{ label }}</h3>
    <span class="required-label">* {{ "FORM.FIELDS.PAYMENT_METHODS.LABELS.IS_REQUIRED" | translate }}</span>
  </div>
  <mat-button-toggle-group name="paymentMethod" aria-label="Payment Method" fxLayout="row" [formControl]="control">
    <mat-button-toggle [value]="method.value" *ngFor="let method of methods$ | async" [ngClass]="{'one-option': (methods$ | async)?.length < 2}" fxFlex>
      {{ method.label }}
    </mat-button-toggle>
  </mat-button-toggle-group>
  <mat-error *ngIf="control.invalid && control.touched">{{ "FORM.FIELDS.PAYMENT_METHODS.VALIDATION.REQUIRED" | translate }}</mat-error>

  <div [ngSwitch]="control.value" class="payment-provider">

    <div *ngSwitchCase="paymentMethods.SEPA_DIRECT_DEBIT" fxLayout="column">
      <mat-form-field appearance="outline">
        <mat-label>{{ field.ibanLabel }}</mat-label>
        <input
          matInput
          placeholder=""
          [mask]="ibanMask"
          (blur)="ibanBlurHandler()"
          (change)="onChangeValue($event)"
          [formControl]="ddSepaControl">
          <span *ngIf="ibanSuffix" matSuffix>{{ ibanSuffix }}</span>
        <mat-error *ngIf="ddSepaControl.invalid && ddSepaControl.touched">{{ getErrorMessage(ddSepaControl, 'iban') | translate }}</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" *ngIf="displayBic">
        {{ bicLabel }}
        <mat-label>{{ field.bicLabel }}</mat-label>
        <span *ngIf="bicSuffix" matSuffix>{{ bicSuffix }}</span>
        <input
          matInput
          placeholder=""
          [formControl]="bicControl">
        <mat-error *ngIf="bicControl.invalid && bicControl.touched">{{ getErrorMessage(bicControl, 'bic') | translate }}</mat-error>
      </mat-form-field>
      <mat-hint>{{ subText$ | async }}</mat-hint>
    </div>

    <div *ngSwitchCase="paymentMethods.PAYPAL" class="paypal" fxLayout="column" fxLayoutAlign="center center">
      <strong>{{ "FORM.FIELDS.PAYMENT_METHODS.LABELS.SELECTED_PAYPAL" | translate }}</strong>
    </div>

    <div *ngSwitchCase="paymentMethods.IDEAL" class="ideal" fxLayout="column">
      <mat-form-field appearance="outline">
        <mat-select [formControl]="bankControl" [value]="" placeholder='{{ "FORM.FIELDS.PAYMENT_METHODS.LABELS.SELECT_BANK" | translate }}'>
            <mat-option *ngFor="let bank of bankProviders | keyvalue" value="{{ bank.key }}">
              {{ bank.value }}
            </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-error *ngIf="bankControl.invalid && bankControl.touched">{{ "FORM.FIELDS.PAYMENT_METHODS.VALIDATION.BANK_REQUIRED" | translate }}</mat-error>
    </div>

    <div *ngSwitchCase="paymentMethods.CREDIT_CARD" class="credit-card" fxLayout="column">
      <strong>{{ "FORM.FIELDS.PAYMENT_METHODS.LABELS.SELECTED_CREDITCARD" | translate }}</strong>
    </div>
  </div>
