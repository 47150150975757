import { NgModule } from '@angular/core';
import { Routes, RouterModule, UrlSegment } from '@angular/router';
import { TemplateGuard } from './core/guards/template.guard';
import { ROUTE_PATHS } from './core/models/global';
import { ErrorComponent, ThankYouComponent } from './shared/components';
import { PageNotFoundComponent } from './shared/components/page-not-found/page-not-found.component';
import { TemplateLoaderComponent } from './shared/components/template-loader/template-loader.component';

const rootMatcher = (segments: UrlSegment[]) => {
  const rootSegment = segments[0];

  if ( !rootSegment ||
    !rootSegment.path ||
    [ROUTE_PATHS.ERROR, ROUTE_PATHS.PAGE_NOT_FOUND].includes(rootSegment.path as any)
  )  {
    return null;
  }
  return { consumed: [rootSegment] };
};

const childMatcher = (segments: UrlSegment[]) => {
  const childSegment = segments[0];
  if (childSegment.path !== 'error') {
    return { consumed: [childSegment] };
  } else {
    console.log('error');
    return { consumed: [ new UrlSegment('error', {})]}
  }
};

const routes: Routes = [
  {
    /*
      Root matcher is greedy and will consume all first Urlsgements and feed that to the Template Guard.
      The guard will try to resolve the URL with a matching JSON template.
      If resolve fails, the Guard will redirect to `page-not-found`

      The ORDER of the routes matters!
    */
    matcher: rootMatcher,
    canActivate: [TemplateGuard],
    children: [
      // This means a URL segment like `save-the-rainforest` will be matched
      {
        path: '',
        component: TemplateLoaderComponent,
      },
      // This means a URL segment like `save-the-rainforest/error` will be matched
      {
        path: ROUTE_PATHS.ERROR,
        component: ErrorComponent
      },
      /*
        This means a URL segment like `save-the-rainforest/anything else` will be matched
        that is not excluded in the child matcher.
        This allows us to have a dynamic thankyou page slug
        So `save-the-rainforest/thank-you` or `save-the-rainforest/bedankt` etc will work.
        Any of the excluded slugs can be added as a specific child page, like Error path.
      */
      {
        matcher: childMatcher,
        component: ThankYouComponent,
        pathMatch: 'full'
      },
    ]
  },
  {
    path: ROUTE_PATHS.PAGE_NOT_FOUND,
    component: PageNotFoundComponent
  },
  // NOTE: maybe create a separate error component for the eror on the root, or make it multi functional
  {
    path: ROUTE_PATHS.ERROR,
    component: ErrorComponent
  },
  // In case no primary slug is provided, do a redirect.
  {
    path: '',
    redirectTo: ROUTE_PATHS.PAGE_NOT_FOUND,
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

