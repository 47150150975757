<div [class]="field.name">
  <!-- Field for Google Places Autocomplete -->
  <mat-form-field appearance="outline" class="full-address">
    <mat-label>{{ label }}</mat-label>
    <input
      matInput
      [type]="type"
      #addressQuery
      [placeholder]="field.placeholder"
      [formControl]="addressControl"
    >
    <mat-error *ngIf="addressControl.invalid">{{ getErrorMessage(addressControl, type) | translate }}</mat-error>
  </mat-form-field>

  <!-- Zipcode, Housenumber & Suffix Grouping -->
  <div class="zipcode-housenumber-suffix-grouping">
    <mat-form-field appearance="outline" *ngIf="showFields$ | async">
      <mat-label>{{ field.zipcodeLabel || 'Zipcode' }}</mat-label>
      <input
        matInput
        (change)="onChangeValue($event)"
        [required]="isRequiredField('zipcode')"
        [formControl]="zipcode"
      >
      <mat-error *ngIf="zipcode.invalid">{{ getErrorMessage(zipcode, 'text') | translate }}</mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" *ngIf="showFields$ | async">
      <mat-label>{{ field.houseNumberLabel || 'House Nr.' }}</mat-label>
      <input
        matInput
        [formControl]="houseNumber"
        [required]="isRequiredField('houseNumber')"
      >
      <mat-error *ngIf="houseNumber.invalid">{{ getErrorMessage(houseNumber, 'text') | translate }}</mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" *ngIf="showFields$ | async">
        <mat-label>{{ field.houseNumberSuffixLabel || 'Suffix' }}</mat-label>
        <input
          matInput
          [formControl]="houseNumberAddition"
          [required]="isRequiredField('houseNumberAddition')"
        >
    </mat-form-field>
  </div>

  <!-- Street & City Grouping -->
  <div class="street-city-grouping">
    <mat-form-field appearance="outline" *ngIf="showFields$ | async">
      <mat-label>{{ field.streetLabel || 'Street' }}</mat-label>
      <input
        matInput
        [formControl]="street"
        [required]="isRequiredField('street')"
      >
      <mat-error *ngIf="street.invalid">{{ getErrorMessage(street, 'text') | translate }}</mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" *ngIf="showFields$ | async">
      <mat-label>{{ field.cityLabel || 'City' }}</mat-label>
      <input
        matInput
        [formControl]="city"
        [required]="isRequiredField('city')"
      >
      <mat-error *ngIf="city.invalid">{{ getErrorMessage(city, 'text') | translate }}</mat-error>
    </mat-form-field>
  </div>

  <!-- Country Single-Grouping -->
  <mat-form-field class="country-list" appearance="outline" *ngIf="showFields$ | async">
    <mat-label>{{ field.countryLabel || 'Country' }}</mat-label>
    <mat-select
      [(value)]="country"
      [formControl]="country"
      [required]="isRequiredField('country')"
    >
      <mat-option *ngFor="let country of countryList" [value]="country.key">
        {{ country.value }}
      </mat-option>
    </mat-select>
    <mat-error *ngIf="country.invalid">{{ getErrorMessage(country, 'text') | translate }}</mat-error>
  </mat-form-field>
</div>
