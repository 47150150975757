<section
  *ngIf="templateLoaded$ | async as status; else loading"
  fxFlex
  fxLayout="column">
    <app-alert *ngIf="error$ | async as error "[message]="error" [visible]="!!error"></app-alert>
    <app-notice *ngIf="notice$ | async as notice "[message]="notice" [visible]="!!notice"></app-notice>

    <app-header *ngIf="status === 'loaded'" [ngClass]="isThankYouPage ? 'thanksHeader': 'formHeader' "></app-header>
    <router-outlet (activate)="routerActivated($event)"></router-outlet>
    <app-footer *ngIf="status === 'loaded'"></app-footer>

</section>

<ng-template #loading></ng-template>
