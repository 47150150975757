import { Injectable } from '@angular/core';
import { AngularFirestore,  } from '@angular/fire/firestore';
import firebase from 'firebase/app';
import { Observable } from 'rxjs';
import { TRANSACTION_STATUS } from '../../models/global';
import { Transaction } from '../../models/template';
import { clientId } from 'src/environments/client';
import { GtmService } from '../gtm/gtm.service';

const STORAGE_PREFIX = 'FS_';

@Injectable({
  providedIn: 'root'
})
export class TransactionService {

  private transactions = this.db.collection<Transaction>(`customers/${clientId}/transactions`);
  private storage = localStorage; // Change this for localStorage for cross tab transactions

  constructor(
    private db: AngularFirestore,
    private gtmService: GtmService,
  ) {}

  /**
   * Subscribe to a document changes in the Transaction collection with the ID
   */
  transactionValueChanges(id: string): Observable<Transaction> {
    return this.transactions.doc<Transaction>(id).valueChanges();
  }

  collectionValueChanges(): Observable<Transaction[]> {
    return this.transactions.valueChanges();
  }

  /**
   * Add or update a transaction to Firestore. If we recieve an ID, use that to update and existing document.
   * Also store the transaction in the device storage so we can reload the data as long as it is incomplete.
   * Finally return the document ID reference.
   */
  upsert(data: Transaction, id?: string): Transaction {
    id = this.db.createId();
    
    this.storage.setItem(`FS_${id}`, JSON.stringify(data));
    const payload = {
      _ID: id,
      ...data
    };
    this.transactions.doc(id).set(payload);
    return payload;
  }

  /**
   * Fetch the first incomplete transaction we find from the device storage
   */
  fetchInCompleteTransaction(): Transaction {
    const transactions = Object.keys(this.storage).filter(val => val.substring(0,3) == STORAGE_PREFIX);
    for(const key of transactions) {
      const transaction = JSON.parse(this.storage[key]) as Transaction;
      if (transaction.status !== TRANSACTION_STATUS.COMPLETE) {
        return {
          _ID: key.replace(STORAGE_PREFIX, ''),
          ...transaction
        };
      }
    }
  }

  /**
   * Set a transaction to complete. When using sessionstorage, the browser will remove the record automatically when browser is closed
   * Alternatively, you can remove the transaction record from sessionstorage manually.
   * 
   * @param {string} id - The given transaction storage ID.
   */
  completeTransaction(id: string) {
    const transaction = JSON.parse(this.storage.getItem(`FS_${id}`)) as Transaction;
    if (transaction) {
      const successRedirect = transaction.metadata.page.successRedirect;
      // Check if the transaction is needed to pre-fill the next page form on success redirect.
      if (successRedirect && !/^https?:\/\//i.test(successRedirect)) {
        transaction.status = TRANSACTION_STATUS.COMPLETE;
        this.storage.setItem(`FS_${id}`, JSON.stringify(transaction));
      } else {
        // Otherwise make sure not to keep it in the device storage after status complete.
        this.storage.removeItem(`FS_${id}`);
      }
    }
  }

  get(
    code: string,
    operator: firebase.firestore.WhereFilterOp,
    value: string
  ) {
    return this.transactions.ref.where(code, operator, value);
  }

  /**
   * Gets the success redirect origin transaction to pre-fill the current page form.
   * 
   * @param {string} slug - The current page slug 
   * @returns {Transaction}
   */
   getSuccessPrefillTransaction(slug: string): Transaction {
    const transactions = Object.keys(this.storage).filter(val => val.substring(0,3) == STORAGE_PREFIX);
    for(const key of transactions) {
      const transaction = JSON.parse(this.storage[key]) as Transaction;
      if (transaction.status === TRANSACTION_STATUS.COMPLETE && transaction.metadata.page.successRedirect === slug) {
        return {
          _ID: key.replace(STORAGE_PREFIX, ''),
          ...transaction
        };
      }
    }
  }

  /**
   * Deletes a transaction from the device storage by the given ID.
   * 
   * @param {string} id - The given transaction storage ID.
   */
  deleteTransactionFromDevice(id: string) {
    this.storage.removeItem(`FS_${id}`);
  }
}
