import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ROUTE_PATHS } from '../models/global';
import { TemplateService } from '../services/template/template.service';

@Injectable({
  providedIn: 'root'
})
export class TemplateGuard implements CanActivate {
  constructor(
    private router: Router,
    private templateService: TemplateService,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

      const url = route.url[0] ? route.url[0].path : '';
      // To prevent a redirect loop, when hitting any of the Page not Found pages, break out of the resolver
      if (url === ROUTE_PATHS.PAGE_NOT_FOUND) {
        return false;
      }
      this.templateService.utmParams = route.queryParams;
      return this.templateService.fetchTemplate(url, route.queryParams).pipe(
        map(val => {
          if (!!val) {
            return true;
          } else {

            if (url !== ROUTE_PATHS.PAGE_NOT_FOUND) {
              return this.router.parseUrl(ROUTE_PATHS.PAGE_NOT_FOUND);
            } else {
              return false;
            }
          }
        })
      );

  }

}
