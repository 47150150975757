import { BREAKPOINT, DEFAULT_BREAKPOINTS } from '@angular/flex-layout';

export const CUSTOM_BREAKPOINTS = DEFAULT_BREAKPOINTS.map(item => {
  // bring inlin with MAT CDK's break point for a Web device which starts with 840px
  if (item.alias === 'gt-sm') {
    item.mediaQuery = 'screen and (min-width: 840px)';
  }
  return item;
});

export const CustomBreakPointsProvider = {
    provide: BREAKPOINT,
    useValue: CUSTOM_BREAKPOINTS,
    multi: true,
};