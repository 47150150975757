import { NgModule, LOCALE_ID } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutModule } from '@angular/cdk/layout';
import { HttpClientModule } from '@angular/common/http';
import { NgxMaskModule } from 'ngx-mask';
import { environment } from '../../environments/environment';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule, SETTINGS as FIRESTORE_SETTINGS } from '@angular/fire/firestore';
import { AngularFireFunctionsModule, ORIGIN, REGION as FIRESTORE_REGION } from '@angular/fire/functions';
import { GtmLoaderComponent } from './components/gtm-loader/gtm-loader.component';

@NgModule({
  imports: [
    CommonModule,
    LayoutModule,
    HttpClientModule,
    NgxMaskModule.forRoot(),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireFunctionsModule,
  ],
  providers: [
    { provide: FIRESTORE_SETTINGS, useValue: environment.emulator ? { host: 'localhost:8080', ssl: false } : undefined },
    { provide: ORIGIN, useValue: environment.emulator ? 'http://localhost:5001' : undefined },
    { provide: LOCALE_ID, useValue: 'en-NL'},
    { provide: FIRESTORE_REGION, useValue: 'europe-west1'},
  ],
  declarations: [
    GtmLoaderComponent,
  ],
})
export class CoreModule { }
