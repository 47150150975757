import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Observable } from 'rxjs';
import { Dictionary } from 'src/app/core/models/global';
import { LayoutService } from 'src/app/core/services/layout-service/layout.service';


@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ErrorComponent implements OnInit {

  deviceClass$: Observable<Dictionary<boolean>>;


  constructor(
    private layoutService: LayoutService,

  ) { }

  ngOnInit(): void {
    this.deviceClass$ = this.layoutService.breakpoint$;

  }


}
