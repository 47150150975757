import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { GooglePlacesField } from 'src/app/core/models/form-fields';
import { BaseFieldComponent } from '../base-field/base-field.component';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { DynamicPipe } from '../../../pipes/dynamic.pipe';
// Import Google Maps API Types to circumvent namespace issues.
import { } from 'google-maps';

export interface Address {
  houseNumber?: string;
  houseNumberAddition?: string;
  street?: string;
  city?: string;
  zipcode?: string;
  country?: string;
}

@Component({
  selector: 'app-google-places-field',
  templateUrl: './google-places-field.component.html',
  styleUrls: ['./google-places-field.component.scss']
})

/**
 * GooglePlaces Component used to autocomplete user addresses.
 *
 * @author Peter Boersma <peter@growingminds.nl>
 * @author Robert Moelker <robert@growingminds.nl>
 */
export class GooglePlacesFieldComponent extends BaseFieldComponent implements OnInit {
  @Input() field: GooglePlacesField;
  @ViewChild('addressQuery') addressQuery: any;

  /**
   * Formcontrol field used for Google Places Field.
   *
   * @type {FormControl} - Tracks the value and validation status of an individual form control.
   */
  addressControl: FormControl;

  /**
   * Default fields to open/fill on Google Autocomplete.
   *
   * @type {Array<string>} - Keys to check from Google Autocomplete.
   */
  defaultFields: Array<string> = ['houseNumber', 'houseNumberAddition', 'city', 'zipcode', 'street', 'country'];

  /**
   * List of countries set through Angular Translations.
   *
   * @type {Array<string>}
   */
  countryList: Array<string>;

  /**
   * Boolean if the extra fields ( e.g. houseNumber ) needs to be shown or hidden.
   *
   * @type {Observable<boolean>}
   */
  showFields$: Observable<boolean>;

  get type() {
    return this.field.type;
  }

  constructor(translate: TranslateService) {
    super();
    this.addressControl = new FormControl('');

    // Set Translations for CountryList
    translate.get('FORM.FIELDS.COUNTRYLIST').subscribe((translation: Array<string>) => {
      this.countryList = translation;
    });

    // Define all the controls
    for (const [index, property] of this.defaultFields.entries()) {
      this[property] = new FormControl('');
    }
  }

  ngOnInit() {
    // Set Google Places Field to required if field is mapped to required.
    if (this.field.validation.required) {
      this.addressControl.setValidators([Validators.required]);
    }

    this.form.addControl('googlePlaces', this.addressControl);

    if (this.field.value) {
      this.addressControl.setValue(this.field.value);
    }

    this.setAddressControls();
  }

  ngAfterViewInit() {
    // Initiate Google Places.
    this.getPlaceAutocomplete();
  }

  /**
   * Initialize Google Places autocomplete widget.
   *
   * @return {void}
   */
  getPlaceAutocomplete() {
    const autocomplete = new google.maps.places.Autocomplete(this.addressQuery.nativeElement,
      {
        componentRestrictions: {
          // Comma seperated string converted to Array for Country Restriction + precaution removing spaces.
          country: (this.field.countryRestriction ? this.field.countryRestriction.replace(/\s+/g, '').split(',') : 'NL')
        },
        fields: ["address_components"],
        types: ['address']
      });

    // Listen for place_changed event
    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      // Convert & fill address fields.
      this.fillInAddress(autocomplete.getPlace());
    });
  }

  /**
   * Dynamically creates new fields and hides fields not needed in form view.
   *
   * @param {google.maps.places.PlaceResult} place - Result/Response from Google API.
   *
   * @returns {void}
   */
  fillInAddress(place: google.maps.places.PlaceResult) {
    // Reset the fields to make sure all the old data has been removed.
    this.defaultFields.forEach(element => {
      this.form.get(element).setValue('');
    });

    // Fields to convert (tested on Dutch addresses)
    for (const component of place.address_components as google.maps.GeocoderAddressComponent[]) {
      const componentType = component.types[0];
      switch (componentType) {
        case "street_number":
          const houseNumber = component.long_name;

          // Matches the numbers, so the addition can be detached from the number.
          this.form.get('houseNumber').setValue(houseNumber.match(/\d+/).join());
          let houseNumberAddition = houseNumber.replace(/\d+/, '');

          // If first character start with an `-` remove it.
          this.form.get('houseNumberAddition').setValue(
            (houseNumberAddition.charAt(0) === '-') ? houseNumberAddition.substring(1) : houseNumberAddition
          );
          break;
        case "locality":
          this.form.get('city').setValue(component.long_name);
          break;
        case "postal_code":
          this.form.get('zipcode').setValue(component.long_name);
          break;
        case "country":
          this.form.get('country').setValue(component.short_name);
          break;
        case "route":
          this.form.get('street').setValue(component.long_name);
          break;
      }
      this.showFields$ = of(true);
    }
  }

  /**
   * Set field controls for sub fields.
   *
   * @return {void}
   */
  setAddressControls() {
    let countFilledFields = 0;
    for (const [index, property] of this.defaultFields.entries()) {
      if (this.field.validation.required && property !== 'houseNumberAddition') {
        this[property].setValidators(this[property].validator ?
          [this[property].validator, Validators.required] :
          Validators.required,
        );
      }

      this.setFieldRegex(property);
      this.form.addControl(property, this[property]);

      if (this.field[property + 'Value'] !== undefined) {
        countFilledFields++;
        this[property].setValue(this.field[property + 'Value']);
      }
    }

    if (countFilledFields > 0) {
      this.showFields$ = of(true);
    }
  }

  /**
   * Set regex rules for field specified.
   *
   * @param {string} field - Current field key to use for setting regex.
   *
   * @return {void}
   */
  setFieldRegex(field: string) {
    // Check field object for regex configuration.
    for (const [key, value] of Object.entries(this.field)) {
      if (key.match(/Regex/g)) {
        let fieldKey = key.replace('Regex', '');

        if (fieldKey === field && value != '') {
          this[field].setValidators(this[field].validator ?
            [this[field].validator, Validators.pattern(value)] :
            Validators.pattern(value)
          );
        }
      }
    }
  }

  /**
   * Detecting changes in input then we transform the value using the dynamic pipe
   * @param event
   */
  onChangeValue(event): void {
    const pipe = new DynamicPipe();
    if (this.field.zipCodeTransformer) {
      this.form.get('zipcode').setValue(pipe.transform(event.target.value, this.field.zipCodeTransformer));
    }
  }
}
