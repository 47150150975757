<div class="mat-form-field">
  <label *ngIf="label">{{ label }} </label><asterisk *ngIf="asterisk && (label || placement === 'vertical')">{{ asterisk }}</asterisk>
  <mat-radio-group
    [fxLayout]="direction"
    [formControl]="control"
    fxLayoutGap="8px">
    <mat-radio-button class="example-radio-button" *ngFor="let option of options" [value]="option.value">
      {{option.label}}
    </mat-radio-button>

    <asterisk *ngIf="asterisk && !label && placement === 'horizontal'">{{ asterisk }}</asterisk>
  </mat-radio-group>
  <mat-error *ngIf="control.invalid && control.touched">{{ getErrorMessage(control, type) | translate }}</mat-error>
</div>