import { Input, OnDestroy } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators, ValidatorFn, ValidationErrors, AbstractControl } from '@angular/forms';
import { DonationFrequency } from 'src/app/core/models/donation-template';
import { TemplateService } from 'src/app/core/services/template/template.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil, map, startWith } from 'rxjs/operators';
import {PaymentMethodField, PaymentMethodOption, PaymentMethods, BankProviders, FormFieldType} from 'src/app/core/models/form-fields';
import { BaseFieldComponent } from '../base-field/base-field.component';
import { TemplateTypes } from 'src/app/core/models/template';
import { CurrencyUtil } from 'src/app/core/utils/currency.util';
import { TranslateService } from '@ngx-translate/core';
import { isValid as IbanIsValid } from 'iban';
import { DynamicPipe } from '../../../pipes/dynamic.pipe';

@Component({
  selector: 'app-payment-method-field',
  templateUrl: './payment-method-field.component.html',
  styleUrls: ['./payment-method-field.component.scss']
})
export class PaymentMethodFieldComponent extends BaseFieldComponent implements OnInit, OnDestroy {
  @Input() field: PaymentMethodField;
  @Input() form: FormGroup;

  paymentMethods = PaymentMethods;
  bankProviders = BankProviders;
  ibanMask = '';

  control : FormControl;
  creditCardControl: FormControl;
  ddSepaControl: FormControl;
  bicControl: FormControl;
  bankControl: FormControl;
  _methods = new BehaviorSubject<PaymentMethodOption[]>([])
  methods$ = this._methods.asObservable();

  displayBic: boolean = false;

  get label() {
    return (this.field.label !== undefined) ? `${this.field.label}` : '';
  }

  get subText$() {
    return this.form.valueChanges
    .pipe(
      startWith(this.form.value), // we do this to kickstart the subscription with the paymentFrequency value
      map(data => {
        if (this.templateService.content.type === TemplateTypes.DONATE) {
          let frequency: DonationFrequency
          let frequencyText = '';
          let amountText = '';
          let amountValue = 0;
          if (data.donationFrequency) {
            const frequencies = this.templateService.content.heroBox.donationFrequency;
            frequency = frequencies.filter(val => val.value === data.donationFrequency)[0];
            frequencyText = frequency.label.toLowerCase();
          }
          if (data.donationAmount) {
            const amount = frequency.donationAmount.filter(val => val.value === data.donationAmount)[0].value;
            amountValue = (amount === 'other' && this.form.get('donationAmountOther').value) ? this.form.get('donationAmountOther').value : amount;
            amountText = this.currencyUtil.format(amountValue, this.templateService.content.heroBox.donationCurrency);
          }

          // maybe we should include the formControl names into the recipe so we don't get a mismatch
          const params = {
            donationAmount: amountText,
            donationFrequency: this.translate.instant(`FORM.FIELDS.FREQUENCY_AMOUNTS.LABELS.${frequencyText.toUpperCase()}`).toLowerCase(),
          };

          const names = Object.keys(params);
          const vals = Object.values(params);
          const _interpolate = new Function(...names, `return \`${this.field.subText}\`;`);

          return _interpolate(...vals);
        } else {
          return this.field.subText;
        }
      }));
  }
  get ibanSuffix() {
    return this.field.ibanSuffix;
  }
  get bicSuffix() {
    return this.field.bicSuffix;
  }

  private onDestroy = new Subject();

  constructor(
    private templateService: TemplateService,
    private currencyUtil: CurrencyUtil,
    private translate: TranslateService,
    ) {
    super();
    this.control = new FormControl();
    this.bankControl = new FormControl(null, {
      updateOn: 'change',
      validators: [Validators.required]
    });

    this.ddSepaControl = new FormControl(null, [Validators.required, this.ibanValidator()]);
    this.bicControl = new FormControl(null, [Validators.required]);
   }

   /**
    * Iban Validator Function for FormControl Validation.
    *
    * @returns ValidatorFn
    */
   private ibanValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return IbanIsValid(control.value) ? null : {iban: control.value };
    };
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  ngOnInit(): void {
    this.setValidations();
    this.control.valueChanges.subscribe(val => {
      // when user changes payment method, remve unused controls
      this.removeControls(['ddSepaNumber', 'bankProvider']);

      switch (val) {
        case PaymentMethods.SEPA_DIRECT_DEBIT:
          this.form.addControl('ddSepaNumber', this.ddSepaControl);
          break;
        case PaymentMethods.IDEAL:
          this.form.addControl('bankProvider', this.bankControl);
          break;
      }
    });
    this.form.addControl(this.field.name, this.control);

    // If bicControl exists & bicRegex has a value, Add Regex Validation to field.
    if(this.bicControl && this.field.bicRegex) {
      this.bicControl.setValidators([Validators.pattern(this.field.bicRegex)]);
    }

    // set the default value
    if (this.field.value) {
      this.control.setValue(this.field.value);
    }

    // ONLY FOR DONATE. Check if payment methods should be hidden based on freq
    const donationFrequencyCtrl = this.form.get('donationFrequency');
    if (donationFrequencyCtrl) {
      // run it for the current frequency value
      this.filterPaymentMethods(donationFrequencyCtrl.value);
      // set up a listener to frequency value changes
      donationFrequencyCtrl.valueChanges
        .pipe(takeUntil(this.onDestroy))
        .subscribe(val => this.filterPaymentMethods(val));
    }
  }

  /**
   * This function filters the payments methods based on the selected frequency.
   * If there was a default payment method provided, we need to check if that is part of the list
   * of current payment types for the selected frequency.
   */
  private filterPaymentMethods(donationFrequency: string) {

    // create a list of methods that match the current frequency
    const methods = this.field.methods.filter(method => method.frequencies.includes(donationFrequency));

    // create a list of values of the methods
    const methodValues = methods.map(val => val.value);

    // set a payment method if the default value is part of the current payment methods based on the freq
    this.control.setValue(methodValues.includes(this.control.value) ? this.control.value : methodValues[0]);
    // mark as untouched to hide any error
    this.control.markAsUntouched();
    // publish the methods for our template to consume
    this._methods.next(methods);
  }

  private removeControls(controls: string[]) {
    controls.forEach(control => this.form.removeControl(control));
  }

  /**
   * Handle logic on IBAN field blur.
   *
   * @return void
   */
  private ibanBlurHandler(): void{
      // if the iban value is set and its not a dutch iban, show the bic field
      if (this.ddSepaControl.value === null || this.ddSepaControl.value == "" || this.ddSepaControl.value.toLowerCase().substring(0,2) == 'nl'){
        this.displayBic = false;
        this.removeControls(['bicControl']);
      } else {
        this.displayBic = true;
        this.form.addControl('bicControl', this.bicControl);
      }
  }

  /**
   * Detecting changes in input then we transform the value using the dynamic pipe
   * @param event
   */
  onChangeValue(event): void {
    const pipe = new DynamicPipe();
    this.ddSepaControl.setValue(pipe.transform(event.target.value, 'stripSpacesAndUpperCaseConverter'));
  }

}
