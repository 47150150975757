import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Observable } from 'rxjs';
import { TemplateService } from 'src/app/core/services/template/template.service';

@Component({
  selector: 'app-social-shares',
  templateUrl: './social-shares.component.html',
  styleUrls: ['./social-shares.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SocialSharesComponent implements OnInit {
  socialShares$: Observable<any>;

  constructor(
    private templateService: TemplateService,
  ) { }

  get currentUrlStripped() {
    return window.location.origin + '/' +
    window.location.pathname.split('/')[1];
  }

  ngOnInit(): void {
    this.socialShares$ = this.templateService.socialShares$;
  }
}
