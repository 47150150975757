import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { DomSanitizer } from '@angular/platform-browser';
import { CheckboxField } from 'src/app/core/models/form-fields';
import { BaseFieldComponent } from '../base-field/base-field.component';

@Component({
  selector: 'app-checkbox-field',
  templateUrl: './checkbox-field.component.html',
  styleUrls: ['./checkbox-field.component.scss']
})
export class CheckboxFieldComponent extends BaseFieldComponent  implements OnInit {
  @Input() field: CheckboxField;

  //
  get label() {
    return this.field.label;
    /*
    By pass security breaks the `<a href></a>` provided in the label. Return the string for now.
    TODO; Fix this how it should be.
    return this.sanitizer.bypassSecurityTrustHtml(this.field.label) as string;
    */
  }

  constructor(
    private sanitizer: DomSanitizer,
  ) {
    super();
    this.control = new FormControl('');
   }

  ngOnInit(): void {
    this.setValidations();
    this.form.addControl(this.field.name, this.control);
    if (this.field.value) {
      this.control.setValue(this.field.value);
    }

    // Set the value of the control if default is true.
    if(!this.field.value && this.field.checked) {
      this.control.setValue(this.field.checked);
    }
  }

  onChange(event: MatCheckboxChange): void {
    this.control.setValue(event.checked);
  }
}
