
import { FormField } from './form-fields';
import { Dictionary } from './global';
import { DonationContent, DonationTemplate } from './donation-template';
import { SubscriptionContent, SubscriptionTemplate } from './subscription-template';

export enum TemplateTypes {
  DONATE = 'DONATE',
  SUBSCRIBE = 'SUBSCRIBE',
  UNSUBSCRIBE = 'UNSUBSCRIBE',
  PETITION = 'PETITION'
}

export type templateTypes = keyof typeof TemplateTypes;
export type Templates = DonationTemplate | SubscriptionTemplate;
export type ContentVariations = DonationContent | SubscriptionContent;

export interface BaseTemplate {
  type: templateTypes;
  counter?: Counter;
  metadata: Metadata;
  socialShares: SocialShares;
  content: BaseContent;
  form: Form;
  styles: Styles;
}

export interface BaseContent {
  type: templateTypes;
  header: Header;
  article: Article;
  footer: Footer;
  thankYou: { text: string };
}

export interface Counter {
  title?: string;
  label: string;
  type: 'PROGRESS_BAR' | 'FLIP_COUNTER',
  goal: string;
  alterCounter: string;
  displayType: string;
}

export interface Header {
  brandLogo: string;
  logoUrl: string;
  heroImage: string;
  heroImageMobile: string;
  heroText: string;
}
export interface Footer {
  text: string;
}

export interface Article {
  text: string;
}

export interface Metadata {
    page : {
      pageRedirect: string;
      successRedirect: string;
      pageTitle: string;
      metaDescription: string;
      noIndex: string;
      purlRequiredMessage: string;
      purlError: boolean;
      language: string;
      favicon: string;
      previewCode: string;
    },
    transaction : {
      cid: string;
      gtmId: string;
      campaignCode: string;
      campaignId: number;
      destinationId: number;
    }
}

export interface Styles {
  colors: Dictionary<string>;
  styleSheet?: string;
}

export interface Form {
  fields: FormField[];
  rows?: FormField[][];
}

export interface Transaction {
  [key: string]: any;
  _ID: string;
  slug: string;
  status: 'PENDING' | 'COMPLETE' | 'REDIRECT' | 'ERROR' | 'NOTICE';
  errorMessage?: string;
  noticeMessage?: string;
  redirectUrl?: string;
}

export interface SocialShares {
  emailTitle: string;
  emailDescription: string;
  facebookImage: string;
  facebookTitle: string;
  facebookDescription: string;
  twitterText: string;
  whatsappText: string;
  bitlyLink: string;
}
