import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { FormField, FormFieldType } from 'src/app/core/models/form-fields';
import { REGEX_PHONE } from 'src/app/core/models/regex';
import { DynamicPipe } from '../../../pipes/dynamic.pipe';

@Component({
  selector: 'app-base-field',
  template: '',
  providers: [ DynamicPipe ]
})
export class BaseFieldComponent implements OnInit {
  @Input() placeholder: string;
  @Input() field: FormField;
  @Input() form: FormGroup;
  control: FormControl;

  get label() {
    if (this.field.type !== FormFieldType.FREE_TEXT
      && this.field.type !== FormFieldType.BUTTON
    ) {
      let required = '';
      if (this.field.validation && this.field.label) {
        required = Object.keys(this.field.validation).includes('required') ? this.field.validation.required === true ? '*' : '' : '';
      }

      return (this.field.label !== undefined) ? `${this.field.label} ${required}` : '';
    }
  }

  get suffix() {
    if (this.field.type !== FormFieldType.FREE_TEXT
      && this.field.type !== FormFieldType.BUTTON
    ) {
      return this.field.suffix;
    }
  }

  constructor() { }

  ngOnInit(): void {}

  setValidations() {
    // maybe there is a typescript way to not have to use this IF statement to satisfy the TS cnmpiler
    if (this.field.type !== FormFieldType.FREE_TEXT
      && this.field.type !== FormFieldType.BUTTON) {

      if (!this.field.validation) { return; }
      const validators = [];

      if (this.field.type === FormFieldType.EMAIL) {
        validators.push(Validators.email);
      }

      if (this.field.validation.required) {
        validators.push(Validators.required);
      }

      if (this.field.validation.phone) {
        validators.push(Validators.pattern(REGEX_PHONE));
      }

      if (this.field.validation.minLength) {
        validators.push(Validators.minLength(this.field.validation.minLength));
      }
      if (this.field.validation.maxLength) {
        validators.push(Validators.maxLength(this.field.validation.maxLength));
      }

      if (this.field.validation.regex) {
        validators.push(Validators.pattern(this.field.validation.regex));
      }
      this.control.setValidators(validators);
    }

  }

  getErrorMessage(control: AbstractControl, type: string): string {
    const error = Object.keys(control.errors)[0];
    switch (error) {
      case 'required':
        return _('FORM.VALIDATION.REQUIRED');
      case 'email':
        return _('FORM.VALIDATION.EMAIL');
      case 'mask':
        if (type === 'tel') {
          return _('FORM.VALIDATION.PHONE');
        } else {
          return _('FORM.VALIDATION.INCORRECT_PATTERN');
        }
      case 'minLength':
        return _('FORM.VALIDATION.MIN_LENGTH');
      case 'maxLength':
        return _('FORM.VALIDATION.MAX_LENGTH');
      case 'iban':
      case 'pattern':
        return _('FORM.VALIDATION.INCORRECT_PATTERN');
    }
    return '';

  }

  /**
   * Find out if the given field contains the required validation
   *
   * @param {string} field - the formField name
   *
   * @returns {boolean} - true / false
   */
  isRequiredField(field : string) : boolean {
    const formField = this.form.get(field);
    if (!formField.validator) return false;

    const validator = formField.validator({} as AbstractControl);
    return (validator && validator.required);
  }

  /**
   * Detecting changes in input then we transform the value using the dynamic pipe
   * @param event
   */
  onChangeValue(event): void {
    const pipe = new DynamicPipe();
    // If statement defined for the TS compiler warning
    if (this.field.type !== FormFieldType.FREE_TEXT && this.field.type !== FormFieldType.BUTTON) {
      if (this.field.transformer) {
        this.control.setValue(pipe.transform(event.target.value, this.field.transformer));
      }
    }
  }
}
