<mat-form-field appearance="outline" fxFlex>
  <mat-label>{{ label }}</mat-label>
  <input
    (change)="onChangeValue($event)"
    matInput
    [type]="type"
    [mask]="mask"
    [placeholder]="placeholder"
    [formControl]="control"
  >
  <mat-error *ngIf="control.invalid">{{ getErrorMessage(control, type) | translate }}</mat-error>
  <span *ngIf="suffix" matSuffix>{{ suffix }}</span>
</mat-form-field>
