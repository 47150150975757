<section
  *ngIf="templateType$ | async as templateType; else loading"
  fxLayout="column">

  <div *ngIf="templateType === templateTypes.SUBSCRIBE">
    <div *ngComponentOutlet="SubscribeTemplateComponent;" ></div>
  </div>

  <div *ngIf="templateType === templateTypes.DONATE">
    <div *ngComponentOutlet="DonateTemplateComponent;" ></div>
  </div>

  <!-- <div *ngIf="templateType === templateTypes.PETITION">
    <div *ngComponentOutlet="DonateTemplateComponent;" ></div>
  </div> -->

  <!-- etc... -->
</section>

<ng-template #loading></ng-template>
