import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable, Subject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { SubscriptionHeroBox } from 'src/app/core/models/subscription-template';
import { TemplateService } from 'src/app/core/services/template/template.service';
import { TransactionService } from 'src/app/core/services/transactions/transaction.service';

@Component({
  selector: 'app-hero-box',
  templateUrl: './hero-box.component.html',
  styleUrls: ['./hero-box.component.scss']
})
export class HeroBoxComponent implements OnInit {

  @Input() heroBox: SubscriptionHeroBox;
  @Input() form: FormGroup;

  counterValue$: Observable<number>;
  showCounter = false;
  counterGoal: number;
  counterLabel: string;

  private onDestroy = new Subject();

  get title() {
    return this.heroBox.title;
  }

  get subTitle() {
    return this.sanitizer.bypassSecurityTrustHtml(this.heroBox.subTitle);
  }

  constructor(
    private sanitizer: DomSanitizer,
    private templateService: TemplateService,
    private transactionService: TransactionService
  ) { }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  ngOnInit(): void {
    const { metadata, counter } = this.templateService;
          // Counter handler
          if (counter) {
            this.showCounter = true;
            this.counterGoal = parseInt(counter.goal, 10);
            this.counterLabel = counter.label;

            // hook into collection update so that we can trigger a new query to get the counter value
            this.counterValue$ = this.transactionService.collectionValueChanges()
              .pipe(
                switchMap(async val => {
                  return await this.transactionService.get('campaignCode', '==', metadata.transaction.campaignCode)
                    .where('status', '==', 'COMPLETE').get()
                }),
                map(val => val.size + parseInt(counter.alterCounter, 10)),
              )
          }
  }
}
