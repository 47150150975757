import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { map, tap } from 'rxjs/operators';
import { Dictionary } from '../../models/global';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {

  breakpoint$ = this.breakpointObserver.observe([
    Breakpoints.Handset,
    Breakpoints.Tablet,
    Breakpoints.Web,
  ]).pipe(
    map( data =>  {
      const deviceClass = {
        'is-mobile': this.breakpointObserver.isMatched(Breakpoints.Handset),
        'is-mobile-landscape': this.breakpointObserver.isMatched(Breakpoints.HandsetLandscape),
        'is-mobile-portrait': this.breakpointObserver.isMatched(Breakpoints.HandsetPortrait),
        'is-tablet': this.breakpointObserver.isMatched(Breakpoints.Tablet),
        'is-tablet-portrait': this.breakpointObserver.isMatched(Breakpoints.TabletPortrait),
        'is-tablet-landscape': this.breakpointObserver.isMatched(Breakpoints.TabletLandscape),
        'is-desktop': this.breakpointObserver.isMatched(Breakpoints.Web)
      }
      return deviceClass;
    })
  );

  constructor(
    public breakpointObserver: BreakpointObserver,
  ) {
  }


}
