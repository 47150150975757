import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Dictionary } from 'src/app/core/models/global';
import { Footer } from 'src/app/core/models/template';
import { LayoutService } from 'src/app/core/services/layout-service/layout.service';
import { TemplateService } from 'src/app/core/services/template/template.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  footer$: Observable<Footer>;
  deviceClass$: Observable<Dictionary<boolean>>;

  constructor(
    private sanitizer: DomSanitizer,
    private layoutService: LayoutService,
    private templateService: TemplateService,
  ) { }

  ngOnInit(): void {
    this.deviceClass$ = this.layoutService.breakpoint$;
    this.footer$ = this.templateService.content$.pipe(
      map(val => val.footer)
    );
  }

  getFooter(footer: Footer) {
    return this.sanitizer.bypassSecurityTrustHtml(footer.text);
  }
}
