import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { HeroBoxComponent } from './components/hero-box/hero-box.component';
import { SubscribeTemplateComponent } from './containers/template/template.component';


@NgModule({
  declarations: [
    SubscribeTemplateComponent,
    HeroBoxComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
  ]
})
export class SubscribeModule { }
