<div class="counter" fxLayout="column" fxLayoutAlign="center start">
  <div
    #counterBar
    fxLayout="column"
    fxLayoutAlign="center end"
    class="counter-bar"
    [style.width]="counterWidth">
    <span class="bar-text">{{counterWidth}}</span>
  </div>
</div>

<span class="goal">{{ isCurrency ? (counterValue | currency:this.donationCurrency:'symbol': (counterValue % 1 == 0) ? '1.0-0' : '1.2-2') : counterValue }} {{ label }}</span>
