<header fxLayout="column" *ngIf="header$ | async as header" fxLayoutAlign="start center" [ngClass]="deviceClass$ | async">
  <div class="fs-inner-header" fxLayout="column" [style.backgroundImage]="heroImage(header)">
    <div fxLayout="column" fxLayoutAlign="start start">
      <a [attr.href]="header.logoUrl" target="_blank">
        <img [attr.src]="header.brandLogo" class="fs-brand-logo">  
      </a>
    </div>
    <div fxLayout="row" fxFlex fxLayoutGap="60px">
      <div fxLayout="column" fxLayoutAlign="end start" fxFlex>
        <h1 class="mat-h1 fs-hero-title">{{ header.heroText }}</h1>
      </div>
      <div fxFlex="50" fxHide.xs fxHide.sm></div>
    </div>
  </div>
</header>
