import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Dictionary } from 'src/app/core/models/global';
import { Header } from 'src/app/core/models/template';
import { LayoutService } from 'src/app/core/services/layout-service/layout.service';
import { TemplateService } from 'src/app/core/services/template/template.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {


  deviceClass$: Observable<Dictionary<boolean>>;
  header$: Observable<Header>;
  isMobile = false;
  isTabletPortrait = false;

  constructor(
    private layoutService: LayoutService,
    private templateService: TemplateService,
  ) { }

  ngOnInit(): void {
    this.deviceClass$ = this.layoutService.breakpoint$
      .pipe(
        tap(data => {
          this.isMobile = data['is-mobile'];
          this.isTabletPortrait = data['is-tablet-portrait'];
        })
      );

      this.header$ = this.templateService.content$.pipe(
        map(val => val.header)
      );
  }

  heroImage(header: Header) {
    if (this.isMobile || this.isTabletPortrait) {
      return `url(${header.heroImageMobile})`;
    } else {
      return `url(${header.heroImage})`;
    }
  }
}
