<section
  *ngIf="content$ | async as content"
  fxLayout="column">
  <main [ngClass]="deviceClass$ | async" fxLayout="column" fxLayoutAlign="start center">
    <div class="inner-main" fxLayout="column" [class]="content.article.text ? 'two-page-column' : 'one-page-column'">
      <div  fxLayout.gt-sm="row" fxLayout="column" fxLayoutGap.gt-sm="60px">

        <article *ngIf="content.article.text" class="left-column" fxFlex="100" fxLayout="column" fxLayoutAlign="start end" fxFlexOrder="2" fxFlexOrder.gt-sm="1">
           <app-content-article [article]="content.article"></app-content-article>
        </article>

        <article class="right-column" [fxFlex]="content.article.text ? '50' : '100'" fxLayout="column" fxLayoutAlign="start start" fxFlexOrder="1" fxFlexOrder.gt-sm="2">
          <app-hero-box
            [heroBox]="content.heroBox"
            [form]="form"
            fxLayout="column">
          </app-hero-box>
          <app-form-engine [form]="form" fxFlex *ngIf="!(purlError$ | async); else purlBlock"></app-form-engine>
          <ng-template #purlBlock class="purl-error" >
            <article class="purl-error" [innerHtml]="(metaData$ | async)?.page.purlRequiredMessage"></article>
          </ng-template>
        </article>
      </div>
    </div>
  </main>
</section>
