import { CurrencyPipe } from '@angular/common';
import { AfterViewInit, Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-counter',
  templateUrl: './counter.component.html',
  styleUrls: ['./counter.component.scss'],
  providers:  [CurrencyPipe]
})
export class CounterComponent implements AfterViewInit {

  @ViewChild('counterBar') counterBar: ElementRef;
  @Input() label: string;
  @Input() goal: number;
  @Input() isCurrency: boolean = false;
  @Input() donationCurrency: string;
  @Input()
  set count(val) {
    if (val) {
      let percentage = Math.ceil(val / this.goal * 100);
      percentage = percentage > 100 ? 100 : percentage;
      this.counterWidth = `${percentage}%`;

      // On first load, only show the value when the bar finishes animating.
      // After that set the counterValue directly
      this.delayedCounterValue = val;
      if (!this.firstLoad) {
        this.counterValue = val;
      }
    }
  }
  delayedCounterValue = 0;
  counterWidth = '0%';
  firstLoad = true;
  counterValue = 0;

  constructor(
    private currency: CurrencyPipe
  ) { }

  @HostListener('transitionend')
  onTransitionEnd(): void {
    // Listen to transitionend and set needed variables to boostrap counter.
    this.firstLoad = false;
    this.counterValue = this.delayedCounterValue;
  }

  ngAfterViewInit(): void {
  }
}
