<div class="mat-form-field">
  <label *ngIf="label">{{ label }} </label><asterisk *ngIf="asterisk && (label || placement === 'vertical')">{{ asterisk }}</asterisk>
  <mat-radio-group
    [fxLayout]="direction"
    [formControl]="control"
    fxLayoutGap="8px"
    (change)="onChange($event)"
    >
    <mat-radio-button 
    disableRipple="true" 
    color="false" 
    class="example-radio-button" 
    *ngFor="let value of values"
    [value]="value.value"
    [name]="value.name"
    >
      {{value.label}}
    </mat-radio-button>

    <asterisk *ngIf="asterisk && !label && placement === 'horizontal'">{{ asterisk }}</asterisk>
  </mat-radio-group>
  <mat-error *ngIf="control.invalid && control.touched">{{ getErrorMessage(control, type) | translate }}</mat-error>
</div>
