<div class="inner-main" *ngIf="socialShares$ | async as socialShares">
    <share-button *ngIf="socialShares.facebookTitle"
      button="facebook" 
      [theme]="'circles-dark'"
      [icon]="['fab', 'facebook-f']"
      [url]="currentUrlStripped"
    ></share-button>

    <share-button *ngIf="socialShares.twitterText" 
      button="twitter" 
      theme="circles-dark" 
      [description]="socialShares.twitterText" 
      [icon]="['fab', 'twitter']" 
      [url]="socialShares.bitlyLink"
    ></share-button>
    
    <share-button *ngIf="socialShares.whatsappText" 
    button="whatsapp" 
    theme="circles-dark" 
    [description]="socialShares.whatsappText" 
    [icon]="['fab', 'whatsapp']" 
    [url]="currentUrlStripped"
    >
    </share-button>

    <share-button *ngIf="socialShares.emailDescription" 
    button="email" 
    theme="circles-dark" 
    [description]="socialShares.emailDescription" 
    [icon]="['fas', 'envelope']" 
    [url]="currentUrlStripped"
    [title]="socialShares.emailTitle"
    ></share-button>
</div>