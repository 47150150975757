import { Component, Input, LOCALE_ID, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators, ValidatorFn, ValidationErrors, AbstractControl } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil, takeWhile, switchMap, startWith } from 'rxjs/operators';
import { DonationAmount, DonationFrequency, DonationHeroBox, DonationTransaction } from 'src/app/core/models/donation-template';
import { TemplateService } from 'src/app/core/services/template/template.service';
import { CurrencyUtil } from 'src/app/core/utils/currency.util';
import { TransactionService } from 'src/app/core/services/transactions/transaction.service';
import { TranslateService } from '@ngx-translate/core';
import { REGEX_AMOUNT_NUMBER_BLOCK } from 'src/app/core/models/regex';
import { Transaction } from 'src/app/core/models/template';

@Component({
  selector: 'app-hero-box',
  templateUrl: './hero-box.component.html',
  styleUrls: ['./hero-box.component.scss'],
  providers: [{
    provide: LOCALE_ID,
    deps: [TemplateService],
    useFactory: (TemplateService) => TemplateService.getLanguage()
  }]
})
export class HeroBoxComponent implements OnInit, OnDestroy {

  @Input() heroBox: DonationHeroBox;
  @Input() form: FormGroup;

  private onDestroy = new Subject();
  protected selectedFrequency: DonationFrequency;
  protected _donationAmount: DonationAmount[];

  showOtherAmountField$: Observable<boolean>;
  showOtherAmountField: boolean;
  donationFrequencyControl : FormControl;
  donationAmountControl: FormControl;
  donationAmountOtherControl: FormControl;

  counterValue$: Observable<number>;
  showCounter = false;
  counterGoal: number;
  counterLabel: string;
  minAmount: number;
  isCurrency: boolean = false;

  get title() {
    return this.heroBox.title;
  }

  get subTitle() {
    return this.sanitizer.bypassSecurityTrustHtml(this.heroBox.subTitle);
  }

  get donationFrequencyTitle() {
    return this.heroBox.donationFrequencyTitle;
  }

  get donationFrequency() {
    return this.heroBox.donationFrequency;
  }

  get donationAmountTitle() {
    return this.heroBox.donationAmountTitle;
  }

  set donationAmount(values: DonationAmount[]) {
    this._donationAmount = values;
  }

  get donationAmount(): DonationAmount[] {
    return this._donationAmount;
  }

  get donationOtherAmountLabel() {
    return this.heroBox.donationOtherAmountLabel;
  }

  get donationAmountRegex() {
    return this.heroBox.donationAmountRegex;
  }

  get donationCurrency() {
    return this.currencyUtil.getCurrencySymbol(this.heroBox.donationCurrency);
  }

  constructor(
    private sanitizer: DomSanitizer,
    private templateService: TemplateService,
    private currencyUtil: CurrencyUtil,
    private transactionService: TransactionService,
    private translateService: TranslateService
  ) { }


  /**
  * Iban Validator Function for FormControl validation.
  *
  * @returns ValidatorFn
  */
  private minAmountValidator(minValue): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return control.value < minValue ? { min: minValue } : null;
    };
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  async ngOnInit() {
    const { metadata, counter } = this.templateService;

    this.donationFrequencyControl = new FormControl(this.heroBox.donationFrequencyDefault);
    this.donationAmountControl = new FormControl('', [Validators.required]);
    this.donationAmountOtherControl = new FormControl('');

    // whenever the frequency changes, we need to load the related amounts of the selected frequency
    this.donationFrequencyControl.valueChanges
      .pipe(takeUntil(this.onDestroy))
      .subscribe(val => {
        // get the frequency object from the available frequencies provided with this template
        this.selectedFrequency = this.heroBox.donationFrequency.filter(item => item.value === this.donationFrequencyControl.value)[0];
        this.donationAmount = this.selectedFrequency ? this.selectedFrequency.donationAmount : [];

        if(this.selectedFrequency.minimumAmount) {
          this.minAmount = +this.selectedFrequency.minimumAmount;
        }

        // Update the donationAmount with a human readable label
        this.donationAmount = this.donationAmount.map(v => ({
          ...v,
          label: (v.value !== 'other') ? this.currencyUtil.format(parseFloat(v.value), this.donationCurrency) : v.value
        }));

        this.showOtherAmountField = false;

        if (this.selectedFrequency.defaultAmount) {
          this.donationAmountControl.setValue(this.selectedFrequency.defaultAmount);
        } else {
          this.donationAmountControl.setValue(null);
        }
      });

    // setValue has to happen after setting up subscription otherwise it will not respond to value set
    this.donationFrequencyControl.setValue(this.heroBox.donationFrequencyDefault);

    this.form.addControl('donationFrequency', this.donationFrequencyControl);
    this.form.addControl('donationAmount', this.donationAmountControl);
    this.form.addControl('donationAmountOther', this.donationAmountOtherControl);

    // if a user selects Other, the control has to becomes required. Else, if they select an amount we
    // need to undo this otherwise we can't get a valid form on submit
    this.showOtherAmountField$ =  this.donationAmountControl.valueChanges
      .pipe(
        map(val => {
          if (val === 'other') {
            this.donationAmountOtherControl.setValidators([Validators.required, this.minAmountValidator(this.minAmount)])
            return true;
          } else {
            this.donationAmountOtherControl.setValidators(null);
            this.donationAmountOtherControl.setValue(null);
            return false;
          }
        })
      );

    // This last observer will override the values of the donation with whatever was in the incomplete transanction
    this.templateService.getTransactionObservable<DonationTransaction>()
      .pipe(
        takeUntil(this.onDestroy),
        takeWhile(data => !!data)
      )
      .subscribe(data => {
        if (data.donationFrequency) {
          this.donationFrequencyControl.setValue(data.donationFrequency);
        }
        if (data.donationAmount) {
          this.donationAmountControl.setValue(data.donationAmount);
        }
        if (data.donationAmountOther) {
          this.donationAmountOtherControl.setValue(data.donationAmountOther);
        }
      });

      // Counter handler
      if (counter) {
        this.showCounter = true;
        this.counterGoal = parseInt(counter.goal, 10);
        this.counterLabel = counter.label;

        // hook into collection update so that we can trigger a new query to get the counter value
        this.counterValue$ = this.transactionService.collectionValueChanges()
          .pipe(
            startWith(),
            switchMap(async val => {
              return await this.transactionService.get('campaignCode', '==', metadata.transaction.campaignCode)
                .where('status', '==', 'COMPLETE').get()
            }),
            map(val => {
              if (counter.displayType && counter.displayType === 'DONATION_AMOUNT') {
                this.isCurrency = true;

                return val.docs
                  .map(record => {
                    if(record.get('donationAmount') === 'other') {
                      return record.get('donationAmountOther');
                    }

                    return parseInt(record.get('donationAmount'), 10);
                  })
                  .reduce((prev, next) => {
                    return prev + next;
                  }, 0) + parseInt(counter.alterCounter, 10);
                } else {
                  return val.size + parseInt(counter.alterCounter, 10);
              }
            }),
          )
      }
  }

  /**
   * KeyPress handler for DonationAmountOther field.
   *
   * @param {KeyboardEvent} event KeyPress Event
   *
   * @return void
   */
  private donationAmountOtherKeyPressHandler(event: KeyboardEvent): void {
    const regex = new RegExp(REGEX_AMOUNT_NUMBER_BLOCK);

    // Prevents user inputting characters following regex.
    if(regex.test(event.key)) {
      event.preventDefault();
    }
  }

  /**
   * Get validation message key and set translation parameters if specified.
   *
   * @param control FieldControl to check for errrors.
   *
   * @returns Object
   */
  getValidationMessage(control: AbstractControl) {
    const error = Object.keys(control.errors)[0];
    let translationObject = {};

    switch (error) {
      case 'required':
        translationObject = { key: 'FORM.VALIDATION.REQUIRED'};
        break;
      case 'min':
        translationObject = { key: 'FORM.VALIDATION.MIN_AMOUNT', params: { amount: this.donationCurrency + ' ' + this.minAmount }};
        break;
      case 'pattern':
        translationObject = { key: 'FORM.VALIDATION.INCORRECT_PATTERN'};
        break;
    }

    return this.translateService.instant(translationObject['key'], translationObject['params']);
  }
}
