// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
  production: false,
  emulator: false,
  firebase: {
    apiKey: "AIzaSyDcEYPZsLIYA0vFs6uWgVhrj-GTBixQzXk",
    authDomain: "growingminds-sandbox.firebaseapp.com",
    projectId: "growingminds-sandbox",
    storageBucket: "growingminds-sandbox.appspot.com",
    messagingSenderId: "930734465587",
    appId: "1:930734465587:web:60f9c532cf5f4859267946"
  },
  pubsub: {
    projectId: "growingminds-sandbox",
  topics: {
      formData: "fs-form-data",
    }
  },
  apis: {
    gatewayEndpoint: 'https://fs-client-services-bvkn9pv7.ew.gateway.dev',
    recipeEndpoint: 'https://dev.recipes.services.gms.sh',
    recipeName: 'recipe.json'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
