import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from './modules/material/material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { IConfig, NgxMaskModule } from 'ngx-mask'
import * as fromComponents from './components';
import { RouterModule } from '@angular/router';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { CustomBreakPointsProvider } from '../core/providers/flex-layout.provider';
import { TranslateModule } from '@ngx-translate/core';
import { ShareModule } from 'ngx-sharebuttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { ShareButtonModule } from 'ngx-sharebuttons/button';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faTwitterSquare, faWhatsapp, faTwitter, faFacebookF } from '@fortawesome/free-brands-svg-icons';

const maskConfig: Partial<IConfig> = {
  dropSpecialCharacters: false 
};

const icons = [ 
  faTwitterSquare,
  faWhatsapp,
  faTwitter,
  faEnvelope,
  faFacebookF
];

@NgModule({
  declarations: [
    ...fromComponents.components,
    PageNotFoundComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    RouterModule,
    FlexLayoutModule,
    TranslateModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot(maskConfig),
    ShareModule,
    ShareIconsModule,
    ShareButtonModule
  ],
  exports: [
    MaterialModule,
    FlexLayoutModule,
    TranslateModule,
    ReactiveFormsModule,
    ...fromComponents.components,
  ],
  providers: [
    CustomBreakPointsProvider, // Adds breakpoints mediaQueries
  ],
})

export class SharedModule {
  constructor(iconLibrary: FaIconLibrary) {
    iconLibrary.addIcons(...icons);
  }
}
