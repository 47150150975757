<h2 class="mat-headline fs-">{{ title }}</h2>

<app-counter
  *ngIf="showCounter"
  [count]="counterValue$ | async"
  [goal]="counterGoal"
  [label]="counterLabel"
  [isCurrency]="isCurrency"
  [donationCurrency]="this.heroBox.donationCurrency"
  fxLayout="column">
</app-counter>

<div [innerHtml]="subTitle" class="mat-body-2"></div>

<h4 class="mat-title">{{ donationFrequencyTitle }}</h4>

<mat-button-toggle-group name="donationFrequency" aria-label="Donation interval" fxLayout="row" [formControl]="donationFrequencyControl">
  <mat-button-toggle [value]="item.value" *ngFor="let item of donationFrequency" [ngClass]="{'one-option': donationFrequency.length < 2}" fxFlex>
    {{ 'FORM.FIELDS.FREQUENCY_AMOUNTS.LABELS.' + item.label.toUpperCase() | translate }}
  </mat-button-toggle>
</mat-button-toggle-group>

<h4 class="mat-title">{{ donationAmountTitle }}</h4>

<mat-button-toggle-group
  name="donationAmount"
  aria-label="Donation interval"
  fxLayout="row"
  [formControl]="donationAmountControl">

  <mat-button-toggle [value]="item.value" *ngFor="let item of donationAmount" [ngClass]="{'one-option': donationAmount.length < 2}" fxFlex>
    <div *ngIf="item.label !== 'other'; else elseBlock">
      {{ item.value | currency:this.heroBox.donationCurrency:'symbol': (item.value % 1 == 0) ? '1.0-0': '1.2-2'}}
    </div>
    <ng-template #elseBlock>{{ "FORM.FIELDS.FREQUENCY_AMOUNTS.LABELS." + item.label.toUpperCase() | translate }}</ng-template>
  </mat-button-toggle>
</mat-button-toggle-group>

<mat-error *ngIf="donationAmountControl.invalid && donationAmountControl.touched">{{ "FORM.FIELDS.FREQUENCY_AMOUNTS.VALIDATION.CHOOSE_AMOUNT" | translate }}</mat-error>

<mat-form-field class="donation-amount-other" appearance="outline" *ngIf="showOtherAmountField$ | async">
  <mat-label>{{ donationOtherAmountLabel }}</mat-label>

  <input
    type="number"
    matInput
    (keypress)="donationAmountOtherKeyPressHandler($event)"
    [formControl]="donationAmountOtherControl">

    <span matPrefix *ngIf="donationCurrency">{{ donationCurrency }} &nbsp;</span>
   <mat-error
      *ngIf="donationAmountOtherControl.invalid && donationAmountOtherControl.touched">
      {{ getValidationMessage(donationAmountOtherControl) }}
    </mat-error>
</mat-form-field>
