import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { ContentVariations } from 'src/app/core/models/template';
import { Dictionary } from 'src/app/core/models/global';
import { LayoutService } from 'src/app/core/services/layout-service/layout.service';
import { TemplateService } from 'src/app/core/services/template/template.service';

@Component({
  selector: 'app-template',
  templateUrl: './template.component.html',
  styleUrls: ['./template.component.scss']
})
export class SubscribeTemplateComponent implements OnInit {

  form: FormGroup;
  content$: Observable<ContentVariations>;
  metaData$: Observable<any>;
  purlError$: Observable<boolean>;
  deviceClass$: Observable<Dictionary<boolean>>;

  constructor(
    private templateService: TemplateService,
    private layoutService: LayoutService,
  ) {
    this.content$ = this.templateService.content$;
    this.metaData$ = this.templateService.metadata$;
    this.purlError$ = this.templateService.purlError$;
  }

  ngOnInit(): void {
    // scroll to top once the page is loaded
    window.document.body.scrollTop = 0;
    this.deviceClass$ = this.layoutService.breakpoint$;
    this.form = new FormGroup({}, {
      updateOn: 'blur'
    });
  }
}
