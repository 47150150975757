<h2 class="mat-headline fs-">{{ title }}</h2>

<app-counter
  *ngIf="showCounter"
  [count]="counterValue$ | async"
  [goal]="counterGoal"
  [label]="counterLabel"
  fxLayout="column">
</app-counter>

<div [innerHtml]="subTitle" class="mat-body-2"></div>
