import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { templateTypes, TemplateTypes } from 'src/app/core/models/template';
import { TemplateService } from 'src/app/core/services/template/template.service';
import { DonateTemplateComponent } from 'src/app/modules/donate/containers/template/template.component';
import { SubscribeTemplateComponent } from 'src/app/modules/subscribe/containers/template/template.component';

@Component({
  selector: 'app-template-loader',
  templateUrl: './template-loader.component.html',
  styleUrls: ['./template-loader.component.scss']
})
export class TemplateLoaderComponent {

  // List all templates you want to be able to load dynamically here
  DonateTemplateComponent = DonateTemplateComponent;
  SubscribeTemplateComponent = SubscribeTemplateComponent;
  // ..
  // end of template components

  templateTypes = TemplateTypes;
  templateType$: Observable<templateTypes>;

  constructor(
    private templateService: TemplateService,
  ) {
    this.templateType$ = this.templateService.templateType$.pipe();
  }
}
