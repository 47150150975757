import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ButtonField } from 'src/app/core/models/form-fields';
import { DonationFrequency } from 'src/app/core/models/donation-template';
import { TemplateService } from 'src/app/core/services/template/template.service';
import { BUTTON_STATE } from 'src/app/core/models/global';
import { CurrencyUtil } from 'src/app/core/utils/currency.util';
import { TemplateTypes } from 'src/app/core/models/template';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-button-field',
  templateUrl: './button-field.component.html',
  styleUrls: ['./button-field.component.scss']
})
export class ButtonFieldComponent implements OnInit {
  @Output() submit = new EventEmitter()
  @Input() field: ButtonField;
  @Input() form: FormGroup;
  @Input() state: string;

  get label() {
    return this.field.label;
  }

  get isResting() {
    return this.state === BUTTON_STATE.RESTING;
  }
  get isLoading() {
    return this.state === BUTTON_STATE.LOADING;
  }

  constructor(
    private templateService: TemplateService,
    private currencyUtil: CurrencyUtil,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
  }

  get label$() {
    return this.form.valueChanges
    .pipe(
      startWith(this.form.value), // we do this to kickstart the subscription with the paymentFrequency value
      map(data => {
        if (this.templateService.content.type === TemplateTypes.DONATE) {
          let frequency: DonationFrequency
          let frequencyText = '';
          let amountText = '';
          let amountValue = 0;
          if (data.donationFrequency) {
            const frequencies = this.templateService.content.heroBox.donationFrequency;
            frequency = frequencies.filter(val => val.value === data.donationFrequency)[0];
            frequencyText = frequency.label.toLowerCase();
          }
          if (data.donationAmount) {
            const amount = frequency.donationAmount.filter(val => val.value === data.donationAmount)[0].value;
            amountValue = (amount === 'other' && this.form.get('donationAmountOther').value) ? this.form.get('donationAmountOther').value : amount;
            amountText = this.currencyUtil.format(amountValue, this.templateService.content.heroBox.donationCurrency);
          }

          // maybe we should include the formControl names into the recipe so we don't get a mismatch
          const params = {
            donationAmount: amountText,
            donationFrequency: this.translate.instant(`FORM.FIELDS.FREQUENCY_AMOUNTS.LABELS.${frequencyText.toUpperCase()}`).toLowerCase(),
          };

          const names = Object.keys(params);
          const vals = Object.values(params);
          const _interpolate = new Function(...names, `return \`${this.field.label}\`;`);

          return _interpolate(...vals);
        } else {
          return this.field.label
        }
      })
    );
  }

  doSubmit() {
    this.submit.emit(true);
  }
}
