import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { FormField, FormFieldType } from '../../models/form-fields';
import { Dictionary, TransactionState } from '../../models/global';
import { ContentVariations, Counter, Metadata, SocialShares, Styles, Templates, templateTypes, Transaction } from '../../models/template';
import { TransactionService } from '../transactions/transaction.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { clientId } from '../../../../environments/client';
import { GtmService } from '../gtm/gtm.service';
import { PrefillService } from '../prefill/prefill.service';

// Register Locales available in codebase.
import { registerLocaleData } from '@angular/common';
import localeNL from '@angular/common/locales/nl';
import localeEN from '@angular/common/locales/en';
registerLocaleData(localeNL, 'nl');
registerLocaleData(localeEN, 'en');

@Injectable({
  providedIn: 'root'
})
export class TemplateService {

  /* Form Fields Interfaces */
  private _formRows = new BehaviorSubject<FormField[][]>(null);
  formRows$ = this._formRows.asObservable();
  language: string;

  get formRows() {
    return this._formRows.value;
  }
  set formRows(rows: FormField[][]) {
    this._formRows.next(rows);
  }

  /* Form Fields Interfaces */

  private _content = new BehaviorSubject<ContentVariations>(null);
  content$ = this._content.asObservable();

  get content() {
    return this._content.value;
  }
  set content(content: ContentVariations) {
    this._content.next(content);
  }

  private _title = new BehaviorSubject<string>(null);
  title$ = this._title.asObservable();

  get title() {
    return this._title.value;
  }
  set title(title: string) {
    this._title.next(title);
  }

  private _favIcon = new BehaviorSubject<string>(null);
  favIcon$ = this._favIcon.asObservable();

  get favIcon() {
    return this._favIcon.value;
  }
  set favIcon(favIcon: string) {
    this._favIcon.next(favIcon);
  }

  private _transaction = new BehaviorSubject<any>(null);
  transaction$ = this._transaction.asObservable();
  get transaction() {
    return this._transaction.value;
  }

  set transaction(transaction: any) {
    this._transaction.next(transaction);
  }

  private _styles = new Subject<Styles>()
  styles$ = this._styles.asObservable();

  set styles(styles: Styles) {
    this._styles.next(styles);
  }

  private _slug: string;
  set slug(val: string) {
    this._slug = val;
  }

  get slug() {
    return this._slug;
  }

  private _metadata = new BehaviorSubject<Metadata>(null);
  metadata$ = this._metadata.asObservable();

  set metadata(metadata: Metadata) {
    this._metadata.next(metadata);
  }

  get metadata() {
    return this._metadata.value;
  }

  private _socialShares = new BehaviorSubject<SocialShares>(null);
  socialShares$ = this._socialShares.asObservable();

  set socialShares(socialShares: SocialShares) {
    this._socialShares.next(socialShares);
  }

  get socialShares() {
    return this._socialShares.value;
  }

  private _transactionState = new BehaviorSubject<TransactionState>(null);
  transactionState$ = this._transactionState.asObservable();

  set transactionState(state: TransactionState) {
    this._transactionState.next(state);
  }

  private _counter: Counter;
  set counter(val: Counter) {
    this._counter = val;
  }
  get counter() {
    return this._counter;
  }

  /**
   * Process the UTM params passed in as a object of key/value pairs.
   * Filter out all keys that do not have the prefix `utm_`.
   * Returns string array of concatenated values
   */
  private _utmParams: Dictionary<string> = {};
  set utmParams(params: Dictionary<string>) {
    Object.keys(params).forEach(key => {
      if (key.search(/utm_/) === 0) {
        this._utmParams[key] = params[key];
      }
    });
  }
  get utmParams() {
    return this._utmParams;
  }

  private _styleColors = new Subject<Dictionary<string>>()
  styleColors$ = this._styleColors.asObservable();

  set styleColors(styleColors: Dictionary<string>) {
    this._styleColors.next(styleColors);
  }

  private _templateType = new BehaviorSubject<templateTypes>(null);
  templateType$ = this._templateType.asObservable();

  get templateType() {
    return this._templateType.value;
  }
  set templateType(type: templateTypes) {
    this._templateType.next(type);
  }

  private _templateLoaded = new Subject<string>()
  templateLoaded$ = this._templateLoaded.asObservable();

  private _error = new BehaviorSubject<any>(null);
  error$ = this._error.asObservable();

  get error() {
    return this._error.value;
  }
  set error(error: any) {
    this._error.next(error);
  }

  private _notice = new BehaviorSubject<any>(null);
  notice$ = this._notice.asObservable();

  private _purlError = new BehaviorSubject<boolean>(false);
  purlError$ = this._purlError.asObservable();

  get purlError() {
    return this._purlError.value;
  }
  set purlError(purlError: boolean) {
    this._purlError.next(purlError);
  }

  get notice() {
    return this._notice.value;
  }
  set notice(notice: any) {
    this._notice.next(notice);
  }

  googleTagManagerId: string;

  constructor(
    private http: HttpClient,
    private transactionService: TransactionService,
    private translate: TranslateService,
    private gtmService: GtmService,
    private prefillService: PrefillService,
    private router: Router

  ) {
    this.translate.addLangs(['nl', 'en']);
  }

  /**
   * Fetch the template from the FormSuite server based on the slug that the user has entered
   */
  fetchTemplate(slug: string, queryParams: Dictionary<any>): Observable<Templates | Boolean> {
    this.slug = slug;
    const apiUrl = `${environment.apis.recipeEndpoint}/${clientId}/${slug}/${environment.apis.recipeName}`

    return this.http.get<Templates>(apiUrl, {
      headers: {},
    }).pipe(
      map(templateData => {
        let redirectIsUrl = false;

        // If previewCode exists and is not null, Do checks and redirect if failed.
        if(templateData.metadata.page.previewCode) {
          const NOT_FOUND_URL = `${this.router.routerState.snapshot.url}/page-not-found`;

          if(!queryParams.preview) {
            this.router.navigate([NOT_FOUND_URL]);
          } else {
            if(queryParams.preview !== templateData.metadata.page.previewCode) {
              this.router.navigate([NOT_FOUND_URL]);
            }
          }
        }

        // If pageRedirect exists is not null, Redirect to different page.
        if (templateData.metadata.page.pageRedirect) {

          // Delete the forward slash if it's in the successRedirect
          if (templateData.metadata.page.pageRedirect.includes('/') &&
              templateData.metadata.page.pageRedirect.includes('http') === false) {
            templateData.metadata.page.pageRedirect = templateData.metadata.page.pageRedirect.split('/').join('');
          }
          redirectIsUrl = /^https?:\/\//i.test(templateData.metadata.page.pageRedirect);

          if (redirectIsUrl) {
            window.location.href = templateData.metadata.page.pageRedirect;
          } else {
            this.router.navigate([templateData.metadata.page.pageRedirect]);
          }
        }

        // Delete the forward slash if it's in the successRedirect, but before we need to check if the string doesn't have
        // http
        if (templateData.metadata.page.successRedirect && templateData.metadata.page.successRedirect.includes('/')
           && templateData.metadata.page.successRedirect.includes('http') === false
        ) {
          templateData.metadata.page.successRedirect = templateData.metadata.page.successRedirect.split('/').join('');
        }

        this.setLanguage(queryParams, templateData);

        // setting of tagmanager has to happen before we emit 'loaded'
        this.setTagManager(templateData.metadata.transaction.gtmId);

        if (templateData.form && templateData.form.fields) {
          // Inject donationAmountRegex from paymentMethod field into heroBox.
          templateData.form.fields.forEach(function(element) {
            if(element.type === 'payment') {
              if(element.donationAmountRegex) {
                if(templateData.content.heroBox) {
                  templateData.content.heroBox.donationAmountRegex = element.donationAmountRegex;
                }
              }
            }
          })

          // convert the recipe fields to form rows
          templateData.form.rows = this.translateFormStructure(templateData.form.fields);
        }

        // Set the `pageTitle`
        if (templateData.metadata.page.pageTitle) {
          this.title = templateData.metadata.page.pageTitle;
        }

        // Set the `facicon`
        if (templateData.metadata.page.favicon) {
          this.favIcon = templateData.metadata.page.favicon;
        }

        // Set the `metadata`
        if (templateData.metadata) {
          this.metadata = templateData.metadata;
        }

        // Set the `socialShares`
        if (templateData.socialShares) {
          this.socialShares = templateData.socialShares;
        }

        return templateData;
      }),
      switchMap(templateData => {
        // if we have a MC Id and Hash, fetch the MC data and merge this data + query params into the form
        if (queryParams.id && queryParams.hash) {
          return this.prefillService.getMarketingCloudData(this.slug, queryParams)
            .pipe(
              map(mcPrefillData => {
                const data = {
                  ...mcPrefillData,
                  ...queryParams,
                }
                this.mergeValues(data, templateData.form.rows);

                if (data.error && this.metadata.page.purlRequiredMessage) {
                  this.purlError = true;
                }

                return templateData;
              })
            );
          // else just merge query params into the form
        } else {
          if (this.metadata.page.purlRequiredMessage) {
            this.purlError = true;
          }
          this.mergeValues(queryParams, templateData.form.rows);
        }
        // return the template data along for the next step
        return of(templateData);
      }),
      map(data => {
        // check if there is a incomplete transaction in device storage
        const transaction = this.transactionService.fetchInCompleteTransaction();

        if (transaction) {
          // push the transaction record the Subject so we can use it everywhere
          this._transaction.next(transaction);

          // now merge the transaction details from the device storage into the form to prefill
          this.mergeValuesToForm(data.form.rows, transaction);
        }

        // > Success redirect prefill
        // Check if there is a previous transaction in device storage
        // so it pre-fills the current page form with it.
        const successPrefillTransaction = this.transactionService.getSuccessPrefillTransaction(this.slug);

        // console.log(successPrefillTransaction)

        if (!transaction && successPrefillTransaction) {
          // This needs to be set to null in order to NOT override the previous transaction
          // and to be able to create a new transaction object with a new ID.
          this.transaction = null

          // Now merge the transaction details from the device storage into the form to prefill
          this.mergeValuesToForm(data.form.rows, successPrefillTransaction);

          // Delete the previous transaction from the device storage
          this.transactionService.deleteTransactionFromDevice(successPrefillTransaction._ID)
        }
        // < Success redirect prefill

        data.content.type = data.type;
        this.loadTemplate(data);

        // notify subscribers that template is loaded and ready to be rendered
        this._templateLoaded.next('loaded');
        this._templateLoaded.complete();
        return data;
      }),
      catchError((error) => {
        console.log(error);

        this._templateLoaded.next('error');
        this._templateLoaded.complete();
        return of(false);
      })
    );
  }

  /**
   * Convenience function to return a typed Observable of a transaction record.
   * This would be called from one of the templates so you would know during development time
   * what kind of type you are dealing with.
   */
  getTransactionObservable<T>(): Observable<T> {
    return this._transaction.asObservable() as Observable<T>;
  }

  /**
    * Merge any data into the form field as default values, prefilling the form as a result
    */
  private mergeValues(params: Dictionary<string>, rows: FormField[][]) {
    for (const row of rows) {
      for (const field of row) {
        if (field.type !== FormFieldType.FREE_TEXT
          && field.type !== FormFieldType.BUTTON
        ) {
          if (field.name && params[field.name]) {
            if (field.hideOnPrefill) {
              field.className += ' cdk-visually-hidden';
            }
            field.value = params[field.name];
          }
        }
      }
    }
  }

  /**
   * Merge transaction data into the form field as default values, prefilling the form as a result
   */
  private mergeValuesToForm(rows: FormField[][], transaction: Transaction) {
    for (const row of rows) {
      for (const field of row) {
        if (field.type === FormFieldType.FREE_TEXT || field.type === FormFieldType.BUTTON)
          continue;

        if (String(field.type) === FormFieldType.GOOGLE_PLACES) {
            field.value = transaction['googlePlaces'];
            const addressFields = [
              'houseNumber',
              'houseNumberAddition',
              'city',
              'zipcode',
              'street',
              'country'
            ];
            for (const addressField of addressFields) {
                if (transaction[addressField] !== '') {
                    field[`${addressField}Value`] = transaction[addressField];
                }
            }
        }

        if (field.name && transaction[field.name]) {
          if (field.hideOnPrefill) {
            field.className += ' cdk-visually-hidden';
          }
          field.value = transaction[field.name];
        }
      }
    }
  }

  /**
   * Translate the fields structure into a structure of rows of fields
   */
  private translateFormStructure(fields: FormField[]): FormField[][] {
    const NO_GROUP = '-NO-GROUP-';
    let currentGroup = `${NO_GROUP}`;
    let group: FormField[] = [];
    let newRows: FormField[][] = [];

    fields.forEach(field => {
      // If the field must be excluded, stop from being added to the rows.
      if (field.excludeFromForm) return;

      // when the group of the current field not equals the prev currentGroup, start a new grouping
      if (field.group !== currentGroup) {
        // move the collected fields over to a new row
        if (group.length > 0) {
          newRows = [...newRows, [...group]];
        }
        // reset group array
        group = [];
        // check if we need to set a new group name or a no-group marker
        currentGroup = field.group ?? `${NO_GROUP}`;
      }
      group.push(field);
    });
    newRows = [...newRows, [...group]];
    return newRows;
  }

  /**
   * Set all Observer subjects with values so that other components will be notified of the template
   */
  private loadTemplate(template: Templates) {
    this.metadata = template.metadata;
    this.counter = template.counter;
    this.templateType = template.type;
    this.content = template.content;
    this.formRows = template.form.rows;
    this.styles = template.styles;
  }

  private setLanguage(queryParams: Dictionary<any>, templateData: Templates) {
    // set default language
    let language = 'en';

    if (queryParams.lang) {
      language = queryParams.lang.toLowerCase();
    } else if (templateData.metadata.page.language) {
      language = templateData.metadata.page.language.toLowerCase();
    }

    this.language = language;
    this.translate.setDefaultLang(language);
  }

  private setTagManager(id: string) {
    this.gtmService.config = {
      id,
    };
    of(this.gtmService.addGtmToDom());
  }

  protected getLanguage() {
    return this.language;
  }
}
