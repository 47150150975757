import { FormEngineComponent } from './form-engine/form-engine.component';
import { BaseFieldComponent } from './fields/base-field/base-field.component';
import { CheckboxFieldComponent } from './fields/checkbox-field/checkbox-field.component';
import { FreeTextFieldComponent } from './fields/free-text-field/free-text-field.component';
import { RadioFieldComponent } from './fields/radio-field/radio-field.component';
import { RadioGroupFieldComponent } from './fields/radio-group-field/radio-group-field.component';
import { TextFieldComponent } from './fields/text-field/text-field.component';
import { GooglePlacesFieldComponent } from './fields/google-places-field/google-places-field.component';
import { PaymentMethodFieldComponent } from './fields/payment-method-field/payment-method-field.component';
import { ButtonFieldComponent } from './fields/button-field/button-field.component';
import { ContentArticleComponent } from './content-article/content-article.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { TemplateLoaderComponent } from './template-loader/template-loader.component';
import { SocialSharesComponent } from './social-shares/social-shares.component';
import { ThankYouComponent } from './thank-you/thank-you.component';
import { ErrorComponent } from './error/error.component';
import { AlertComponent } from './alert/alert.component';
import { NoticeComponent } from './notice/notice.component';
import { CounterComponent } from './counter/counter.component';
import { TextareaFieldComponent } from './fields/textarea-field/textarea-field.component';

export const components = [
  BaseFieldComponent,
  TextFieldComponent,
  FormEngineComponent,
  FreeTextFieldComponent,
  RadioFieldComponent,
  RadioGroupFieldComponent,
  CheckboxFieldComponent,
  PaymentMethodFieldComponent,
  TextareaFieldComponent,
  ButtonFieldComponent,
  ContentArticleComponent,
  HeaderComponent,
  FooterComponent,
  TemplateLoaderComponent,
  SocialSharesComponent,
  ThankYouComponent,
  ErrorComponent,
  AlertComponent,
  NoticeComponent,
  CounterComponent,
  GooglePlacesFieldComponent
];

export * from './form-engine/form-engine.component';
export * from './fields/base-field/base-field.component';
export * from './fields/text-field/text-field.component';
export * from './fields/free-text-field/free-text-field.component';
export * from './fields/radio-field/radio-field.component';
export * from './fields/radio-group-field/radio-group-field.component';
export * from './fields/checkbox-field/checkbox-field.component';
export * from './fields/payment-method-field/payment-method-field.component';
export * from './fields/button-field/button-field.component';
export * from './fields/textarea-field/textarea-field.component';
export * from './content-article/content-article.component';
export * from './header/header.component';
export * from './footer/footer.component';
export * from './template-loader/template-loader.component';
export * from './social-shares/social-shares.component';
export * from './thank-you/thank-you.component';
export * from './error/error.component';
export * from './alert/alert.component';
export * from './notice/notice.component';
export * from './counter/counter.component';
export * from './fields/google-places-field/google-places-field.component';