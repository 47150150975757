import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { TemplateService } from '../../services/template/template.service';

@Component({
  selector: 'app-gtm-loader',
  template: ``,
})
export class GtmLoaderComponent implements OnInit {

  constructor(
    @Inject(DOCUMENT) private _document: Document,
    @Inject('Window') private window: Window,
    private render: Renderer2,
    private temlateService: TemplateService,

  ) { }

  ngOnInit(): void {
    this.temlateService.templateLoaded$.subscribe(status => {
      if (status === 'loaded') {
        console.log(this.temlateService.googleTagManagerId);
        if (this.temlateService.googleTagManagerId) {
          this.injectGtmScript(this.temlateService.googleTagManagerId);
        }
      }
    });
  }

  private injectGtmScript(gtmId: string) {
    const layer = 'dataLayer';
    this.window[layer] = this.window[layer] || [];
    window[layer].push({
      'gtm.start': new Date().getTime(),
      event: 'gtm.js'
    });
    const scriptTag = this.render.createElement('SCRIPT') as HTMLScriptElement;
    const dl = layer != 'dataLayer' ? '&l=' + layer : '';
    scriptTag.async = true;
    const firstTag = this._document.getElementsByTagName('SCRIPT')[0];
    scriptTag.src = `https://www.googletagmanager.com/gtm.js?id=${gtmId}` + dl;
    this.render.insertBefore(firstTag.parentNode, scriptTag, firstTag);
  }
}
