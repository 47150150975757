import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { DonationHeroBox } from 'src/app/core/models/donation-template';
import { Dictionary, TRANSACTION_STATUS } from 'src/app/core/models/global';
import { GtmEvent } from 'src/app/core/models/gtml';
import { ContentVariations } from 'src/app/core/models/template';
import { GtmService } from 'src/app/core/services/gtm/gtm.service';
import { LayoutService } from 'src/app/core/services/layout-service/layout.service';
import { TemplateService } from 'src/app/core/services/template/template.service';

@Component({
  selector: 'app-template',
  templateUrl: './template.component.html',
  styleUrls: ['./template.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DonateTemplateComponent implements OnInit {

  form: FormGroup;
  content$: Observable<ContentVariations>;
  deviceClass$: Observable<Dictionary<boolean>>;

  private onDestroy = new Subject();

  constructor(
    private templateService: TemplateService,
    private layoutService: LayoutService,
    private gtmService: GtmService,
  ) {
    this.content$ = this.templateService.content$;
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  ngOnInit() {
    // scroll to top once the page is loaded
    window.document.body.scrollTop = 0;
    this.deviceClass$ = this.layoutService.breakpoint$;
    this.form = new FormGroup({}, {
      updateOn: 'blur'
    });
    this.templateService.transactionState$
      .pipe(
        takeUntil(this.onDestroy),
        filter(state => !!state),
      )
      .subscribe(state => {
        const amount = this.form.get('donationAmountOther').value || this.form.get('donationAmount').value;
        const hero = this.templateService.content.heroBox as DonationHeroBox;
        const currency = hero.donationCurrency;
        const sku = this.templateService.slug;
        const name = this.form.get('donationFrequency').value;
        let nameReadable: string;
        const category = this.form.get('paymentMethod').value;
        let tag: GtmEvent;

        // Set name/ frequency to a readable format.
        switch(<string> this.form.get('donationFrequency').value){
          case '0':
            nameReadable = 'once';
            break;
          case '12':
            nameReadable = 'monthly';
            break;
          case '1':
            nameReadable = 'yearly';
            break;
          case '4':
            nameReadable = 'quarterly';
            break;
          default:
            // As backup if name/frequency does not exist set as standard value.
            nameReadable = <string> this.form.get('donationFrequency').value;
            break;
          }

        switch (state.status) {
          case TRANSACTION_STATUS.COMPLETE:
            tag = {
              event: 'purchase',
              newsletter: false, // to do based on solution how to figure out how to decide how the value shall be calculated.
              transactionId: state.id,
              transactionTotal: amount,
              transactionCurrency: currency,
              transactionProducts: [
                {
                  sku,
                  name: nameReadable,
                  category,
                  price: amount,
                  quantity: 1
                }
              ]
            };
            this.gtmService.pushTag(tag);
            break;
          case TRANSACTION_STATUS.ERROR:
            // tag = {
            //   event: 'error',
            //   transactionId: state.id,
            // };
            // this.gtmService.pushTag(tag);
            break;
        }
      })

  }
}
