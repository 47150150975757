import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ContentVariations } from 'src/app/core/models/template';
import { Dictionary } from 'src/app/core/models/global';
import { LayoutService } from 'src/app/core/services/layout-service/layout.service';
import { TemplateService } from 'src/app/core/services/template/template.service';
import { TransactionService } from 'src/app/core/services/transactions/transaction.service';

@Component({
  selector: 'app-thank-you',
  templateUrl: './thank-you.component.html',
  styleUrls: ['./thank-you.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ThankYouComponent implements OnInit {

  content$: Observable<ContentVariations>;
  deviceClass$: Observable<Dictionary<boolean>>;

  private onDestroy = new Subject();

  constructor(
    private sanitizer: DomSanitizer,
    private layoutService: LayoutService,
    private route: ActivatedRoute,

    private templateService: TemplateService,
    private transactionService: TransactionService,
  ) { }

  ngOnInit(): void {
    this.route.queryParams
      .pipe(takeUntil(this.onDestroy))
      .subscribe(params => {
        if (params.id) {
          this.transactionService.completeTransaction(params.id);
        }
      });
    this.deviceClass$ = this.layoutService.breakpoint$;
    this.content$ = this.templateService.content$;
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  getText(content: ContentVariations): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(content.thankYou.text);
  }
}
