import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { TemplateService } from '../../services/template/template.service';

@Component({
  selector: 'app-style-loader',
  templateUrl: './style-loader.component.html',
})
export class StyleLoaderComponent implements OnInit {

  styles: SafeHtml;

  constructor(
    private sanitizer: DomSanitizer,
    private templateService: TemplateService,
  ) { }

  ngOnInit(): void {
    this.templateService.styles$.subscribe(data => {
      let styleRules = '';
      // MSPI: temp fix, revisit.
      if (!data) {
        return;
      }
      for(const [key, val] of Object.entries(data.colors)) {
        styleRules += `--${key.replace(/([A-Z])/g,'-$&').toLowerCase()}: ${val};\n`;
      }

      this.styles = this.sanitizer.bypassSecurityTrustHtml(`
      <style>
        /* vars have to be injected in the :root for it to work */
        :root {
          ${styleRules}
        }
        ${decodeURIComponent(atob(data.styleSheet))}
      </style>
      `);
    });

  }

}
