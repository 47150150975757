import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TextareaField } from 'src/app/core/models/form-fields';
import { BaseFieldComponent } from '../base-field/base-field.component';

@Component({
  selector: 'app-textarea-field',
  templateUrl: './textarea-field.component.html',
  styleUrls: ['./textarea-field.component.scss']
})
export class TextareaFieldComponent extends BaseFieldComponent implements OnInit {

  @Input() field: TextareaField;

  get mask() {
    return this.field.mask;
  }

  get type() {
    return this.field.type;
  }


  constructor() {
    super();
    this.control = new FormControl('');

   }

  ngOnInit(): void {
    this.setValidations();
    this.form.addControl(this.field.name, this.control);
    if (this.field.value) {
      this.control.setValue(this.field.value);
    }
  }
}
