/**
 * Based on (https://github.com/mzuccaroli/angular-google-tag-manager/) but with a slight change that we can load it when the template
 * is loaded
 */

import { Injectable } from '@angular/core';
import { Dictionary } from '../../models/global';
import { GtmEvent } from '../../models/gtml';

@Injectable({
  providedIn: 'root'
})
export class GtmService {

  private isLoaded = false;

  private browserGlobals = {
    windowRef(): any {
      return window;
    },
    documentRef(): any {
      return document;
    },
  };

  private _config: Dictionary<any>;
  set config(val: Dictionary<any>) {
    this._config = {...val };
  }
  get config() {
    return this._config;
  }

  constructor(
  ) { }

  getDataLayer(): any[] {
    const window = this.browserGlobals.windowRef();
    window.dataLayer = window.dataLayer || [];
    return window.dataLayer;
  }

  addGtmToDom(): Promise<boolean> {
    if (!this.config.id) {
      return;
    }
    return new Promise((resolve, reject) => {
      if (this.isLoaded) {
        return resolve(this.isLoaded);
      }
      const doc = this.browserGlobals.documentRef();
      this.pushOnDataLayer({
        event: 'gtm.js',
        'gtm.start': new Date().getTime(),
      });

      const gtmScript = doc.createElement('script');
      gtmScript.id = 'GTMscript';
      gtmScript.async = true;
      gtmScript.src = this.applyGtmQueryParams(
        'https://www.googletagmanager.com/gtm.js'
      );
      gtmScript.addEventListener('load', () => {
        return resolve(this.isLoaded = true);
      });
      gtmScript.addEventListener('error', () => {
        return reject(false);
      });
      doc.head.insertBefore(gtmScript, doc.head.firstChild);
    });
  }

  pushTag(item: GtmEvent): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (!this.isLoaded) {
        this.addGtmToDom().then(() => {
          this.pushOnDataLayer(item);
          return resolve();
        }).catch(() => reject());
      } else {
        this.pushOnDataLayer(item);
        return resolve();
      }
    });
  }

  private pushOnDataLayer(obj: GtmEvent): void {
    const dataLayer = this.getDataLayer();
    dataLayer.push(obj);
  }

  private applyGtmQueryParams(url: string): string {
    if (url.indexOf('?') === -1) {
      url += '?';
    }

    return (
      url +
      Object.keys(this.config)
        .filter((k) => this.config[k])
        .map((k) => `${k}=${this.config[k]}`)
        .join('&')
    );
  }
}
