export interface Dictionary<T> {
  [key: string]: T;
}

export enum ROUTE_PATHS {
  ERROR = 'error',
  PAGE_NOT_FOUND = 'page-not-found'
};

export enum BUTTON_STATE {
  RESTING = 'RESTING',
  LOADING = 'LOADING'
}

export enum TRANSACTION_STATUS {
  PENDING = 'PENDING',
  COMPLETE = 'COMPLETE',
  REDIRECT = 'REDIRECT',
  ERROR = 'ERROR',
  NOTICE = 'NOTICE'
}

export interface TransactionState {
  id: string;
  status: TRANSACTION_STATUS;
}