import { Component, OnDestroy } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Observable, Subject } from 'rxjs';
import { startWith, takeUntil, tap } from 'rxjs/operators';
import { TemplateService } from './core/services/template/template.service';
import { ThankYouComponent } from './shared/components';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy {

  templateLoaded$: Observable<string>;
  error$: Observable<string>;
  notice$: Observable<string>;
  private onDestroy = new Subject();
  favIcon: HTMLLinkElement = document.querySelector('#favIcon');
  isThankYouPage = false;

  constructor(
    private templateService: TemplateService,
    private titleService: Title,
    private metaService: Meta,
  ) {
    this.error$ = this.templateService.error$;
    this.notice$ = this.templateService.notice$;
    this.templateLoaded$ = this.templateService.templateLoaded$;

    // Handle the dynamic meta tags
    this.templateService.metadata$
    .pipe(takeUntil(this.onDestroy))
    .subscribe(metadata => {
      if (metadata) {
        // Try to set `noindex`
        if (parseInt(metadata.page.noIndex)) {
          this.metaService.addTag({ name: 'robots', content: 'noindex' });
        }

        // Try to set `description`
        if (metadata.page.metaDescription) {
          this.metaService.addTag({ name: 'description', content: metadata.page.metaDescription });
        }
      }
    });

    // Handle the dynamic Open Graph meta tags
    this.templateService.socialShares$
    .pipe(takeUntil(this.onDestroy))
    .subscribe(socialShares => {
      if (socialShares) {
        // Try to set `facebookTitle`
        if (socialShares.facebookTitle) {
          this.metaService.addTag({ property: 'og:title', content: socialShares.facebookTitle });
        }

        // Try to set `facebookImage`
        if (socialShares.facebookImage) {
          this.metaService.addTag({ property: 'og:image', content: socialShares.facebookImage });
        }

        // Try to set `facebookImage`
        if (socialShares.facebookDescription) {
          this.metaService.addTag({ property: 'og:description', content: socialShares.facebookDescription });
        }
      }
    });

    this.templateService.title$
      .pipe(takeUntil(this.onDestroy))
      .subscribe(title => {
        this.titleService.setTitle(title);
      });

    this.templateService.favIcon$
      .pipe(takeUntil(this.onDestroy))
      .subscribe(icon => {
        this.favIcon.href = icon;
      });
  }
  routerActivated(componentRef) {
    // Check if the thank you component is loaded
    if (componentRef instanceof ThankYouComponent) {
      // If loaded add class to header
      this.isThankYouPage = true;
    }
  }
  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
  }
}
