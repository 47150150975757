import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { FormField, FormFieldType } from 'src/app/core/models/form-fields';
import { TemplateService } from 'src/app/core/services/template/template.service';

import { MatButton } from '@angular/material/button';
import { BUTTON_STATE, TRANSACTION_STATUS } from 'src/app/core/models/global';
import { TransactionService } from 'src/app/core/services/transactions/transaction.service';
import { filter, switchMap, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-form-engine',
  templateUrl: './form-engine.component.html',
  styleUrls: ['./form-engine.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormEngineComponent implements OnDestroy {
  @ViewChild('submitButton', { static: false }) submitButton: MatButton;
  @Input() form: FormGroup;

  rows$: Observable<FormField[][]>;
  formFieldTypes = FormFieldType;
  buttonState = BUTTON_STATE.RESTING;

  switchTextFields(type: string) {
    return type === FormFieldType.EMAIL ?
      FormFieldType.EMAIL : type === FormFieldType.TEL ?
        FormFieldType.TEL : FormFieldType.TEXT;
  }

  private onDestroy = new Subject();

  constructor(
    private templateService: TemplateService,
    private router: Router,
    private transactionService: TransactionService,
    private ref: ChangeDetectorRef,
  ) {
    this.rows$ = this.templateService.formRows$;
    // start monitoring for transaction changes which can be for future transaction or in-complete transactions
    // this.templateService.transaction = null;
    this.monitorTransaction();
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  doSubmit(event: boolean) {
    this.buttonState = BUTTON_STATE.LOADING;
    this.form.markAllAsTouched();

    if (!this.form.valid) {
      this.buttonState = BUTTON_STATE.RESTING;
      this.scrollToTop();
      return;
    }

    /*
      Check if there is an incomplete transaction store in the device Storage. In that case we want to
      resume and update the transaction record in firestore with the existing ID.
    */
    const id = this.templateService.transaction ? this.templateService.transaction._ID : null;

    // The `origin` in combination with the `slug` will be used as return URL for the external services.
    const origin = window.location.origin;

    const dateNow = (new Date()).toISOString().slice(0, 19).replace(/-/g, '-').replace('T', ' ');

    // Insert or update the transaction in Firestore
    const transaction = this.transactionService.upsert({
      ...this.form.value,
      ...this.templateService.metadata.transaction,
      metadata: {
        page: this.templateService.metadata.page
      },
      slug: this.templateService.slug,
      status: TRANSACTION_STATUS.PENDING,
      createdAt: dateNow,
      origin: origin,
      utm: this.templateService.utmParams
    }, id);
    this.templateService.transaction = transaction;
  }

  /**
   * Scroll to top if there is an invalid field
   */
  scrollToTop() {
    const selection = document.querySelectorAll('.form-engine .ng-invalid');
    if (selection) {
      const element = selection[0] as HTMLElement;
      element.scrollIntoView({ behavior: 'smooth', inline: 'center' });
    }
  }

  private stopMonitorTransaction() {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  /**
   * Set up an observer to monitor transaction changes
   */
  private monitorTransaction() {
    this.templateService.transaction$.pipe(
      takeUntil(this.onDestroy),
      // filter out null values when there are no incomple transactions
      filter(transaction => !!transaction),
      // switch to observing the transaction value changes using the transaction Id from the prev observable.
      switchMap(transaction => {
        return this.transactionService.transactionValueChanges(transaction._ID).pipe(
          takeUntil(this.onDestroy)
        )
      })).subscribe(transaction => {
        console.log('[Monitor transaction]', transaction);
        if (!transaction) { return; }
        switch (transaction.status) {
          case TRANSACTION_STATUS.REDIRECT:
            window.location.href = transaction.redirectUrl;
            break;

          // this case might not be used since the payment provider will probably redirectd directly to the thank-you page
          case TRANSACTION_STATUS.COMPLETE:
            const pathWithoutParams = this.router.routerState.snapshot.url.split('?')[0];
            let redirect = `${pathWithoutParams}/thank-you`;
            let redirectIsUrl = false;

            // The visitor might need to be redirected to a custom page/path or URL
            // after a successful form submission, instead of the default `thank-you` page.
            // If the given success redirect value is a page/path, the form on the redirected page
            // needs to be pre-filled with the origin form data.
            const successRedirect = this.templateService.metadata.page.successRedirect;

            if (successRedirect) {
              // Check if the success redirect is a URL
              // to know what redirect mechanism to use later on.
              redirectIsUrl = /^https?:\/\//i.test(successRedirect);

              redirect = successRedirect;
            }

            this.transactionService.completeTransaction(transaction._ID);
            this.templateService.transactionState = {
              id: transaction._ID || null,
              status: TRANSACTION_STATUS.COMPLETE
            };
            this.buttonState = BUTTON_STATE.RESTING;

            if (redirectIsUrl) {
              // redirect wil reload the page so we don't have to stop any monitoring
              window.location.href = redirect;
            } else {
              // stop monitoring the transaction
              this.stopMonitorTransaction();
              this.router.navigate([redirect]);
            }

            break;

          case TRANSACTION_STATUS.ERROR:
            // trigger error in app-component
            this.templateService.error = transaction.errorMessage;
            this.templateService.transactionState = {
              id: transaction._ID || null,
              status: TRANSACTION_STATUS.ERROR
            };
            this.buttonState = BUTTON_STATE.RESTING;
            break;

          case TRANSACTION_STATUS.NOTICE:
            // trigger notice in app-component
            this.templateService.notice = transaction.noticeMessage;
            this.buttonState = BUTTON_STATE.RESTING;
            break;
        }
        this.ref.markForCheck();
      });
  }
}
