import {Component, HostBinding, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent  {

  @Input() message = '';
  @Input() @HostBinding('class.visible') visible = false;

  constructor() { }

  hide() {
    this.visible = false;
  }

}
